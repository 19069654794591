import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import '../../utils/polyfill-element-closest';

import {isInternalDestination, isSiblingDestination} from '../../utils/urls';
import {showDisclaimerExternalLink} from '../../state/actions/disclaimer';
import {CLASSNAME_AP_DISCLAIMER_CONTINUE} from '../Layout/DisclaimerExternalLink/DisclaimerExternalLink';

let isAlreadyListening = false;

// ms-life.de', 'mailto', 'cleo-app.de

const hosts = ['biogen.de', 'biogen.com', 'ms-life.de', 'cleo-app.de', 'javascript', 'tel:', 'mailto:'];

class ObserveExternalLinks extends PureComponent {
	static propTypes = {
		onClickOnExternalLink: PropTypes.func.isRequired,
	};

	componentDidMount() {
		// prevent multiple listeners if there is more than one component of this type
		if (!isAlreadyListening) {
			this.startListening();
			isAlreadyListening = true;
		}
	}

	handleClickEvent = (event) => {
		const {onClickOnExternalLink} = this.props;

		const nodeName = event.target.nodeName || '';

		// theHyperlink = event.target, OR if target is not an <a>, then an <a>-ancestor
		const theHyperlink = nodeName && nodeName.toLowerCase() === 'a' ? event.target : event.target.closest('a');

		if (!theHyperlink) {
			// not clicked on/in an <a> element
			return;
		}

		const href = theHyperlink.getAttribute('href'); // NOT: theHyperlink.href

		// no href:
		if (!href) {
			return;
		}

		if (process.env.GATSBY_HOST) {
			if (href.indexOf(process.env.GATSBY_HOST) > -1 || href.indexOf('javascript') > -1) {
				return;
			}
		}

		// do not handle "mailto:"
		if (theHyperlink.href.slice(0, 7) === 'mailto:') {
			return;
		}

		if (
			theHyperlink.href.includes('https://ms-videoline.com/anruf') ||
			theHyperlink.href.includes('http://www.ms360grad.de') ||
			theHyperlink.href.includes('https://www.ms360grad.de')
		) {
			return;
		}

		// special case: the "Weiter"/continue link in the "external-link-disclaimer",
		// which must not be intercepted:
		if (theHyperlink.className.includes(CLASSNAME_AP_DISCLAIMER_CONTINUE)) {
			return;
		}

		if (isInternalDestination(href) || isSiblingDestination(href)) {
			return;
		}

		let exitLinkBehaviour = false;
		for (let i = 0; i < hosts.length; i += 1) {
			const host = hosts[i];
			if (theHyperlink.href.indexOf(host) > -1) {
				exitLinkBehaviour = true;
			}
		}
		if (exitLinkBehaviour) {
			return;
		}
		// Finally: it is an external link that must be intercepted;
		// don't follow the link, instead call prop.onClickOnExternalLink,
		// which will display the disclaimer at the end
		event.preventDefault();
		onClickOnExternalLink(href);
	};

	startListening() {
		document.addEventListener('click', this.handleClickEvent, false);
	}

	render() {
		return null; // because we only use componentDidMount()
	}
}

const mapDispatchToProps = (dispatch) => ({
	onClickOnExternalLink: (href) => dispatch(showDisclaimerExternalLink(href)),
});

export default connect(null, mapDispatchToProps)(ObserveExternalLinks);
