import styled from 'styled-components';
import {zindex, colors, sizes} from '../../../styles/variables';
import {gradientLightgrey, gradientCandyCarrot, media, withUnit} from '../../../styles/utils';
import Button from '../../Elements/Button/Button';

export const ButtonStyled = styled(Button)`
	overflow: visible;
	flex: 0 0 auto;
`;

export const BannerStyled = styled.div`
	box-sizing: border-box;
	z-index: ${zindex.banner};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 2em;
	will-change: margin-top;
	transition: margin-top 0.3s ease-in-out;

	margin-top: ${props =>
		props.isUpstairs ? `${withUnit(sizes.navbar.sm.default)}` : `${withUnit(sizes.navbar.sm.upstairs)}`};

	// Themes
	${props =>
		props.type === 'error'
			? `
			${gradientCandyCarrot()}
			color: ${colors.white};
				`
			: `
				${gradientLightgrey()}
				color: ${colors.black};
			`};
`;

export const BannerContent = styled.div`
	width: 90%;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;

	${media.xl`
		flex-direction: row;
	`};

	a {
		float: right;
	}
`;

export const Blurb = styled.p`
	${props =>
		props.type === 'error'
			? `
		color: ${colors.white};
			`
			: `
			color: ${colors.black};
		`};
	font-size: 1em;
	flex-grow: 1;
	text-align: center;
	margin: 0;
`;
