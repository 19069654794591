/* eslint-disable radix */
import {sizes} from '../styles/variables';

/**
 * Stop scrolling.
 *
 * Fixes the html in a way that the user can't scroll anymore.
 */
export const stopScrolling = () => {
	const html = document.documentElement;
	if (html.style.position !== 'fixed') {
		const top = html.scrollTop ? html.scrollTop : document.body.scrollTop;
		const left = html.scrollLeft ? html.scrollLeft : document.body.scrollLeft;

		if (window.innerWidth > html.clientWidth) {
			html.style.overflowY = 'scroll';
		}
		if (window.innerHeight > html.clientHeight) {
			html.style.overflowX = 'scroll';
		}

		html.style.width = '100%';
		html.style.height = '100%';
		html.style.position = 'fixed';
		html.style.top = `${-top}px`;
		html.style.left = `${-left}px`;
	}

	document.body.style.overflow = 'hidden';
};

/**
 * Start scrolling.
 *
 * Unfixes the html in a way hat the user can scroll again.
 */
export const startScrolling = () => {
	const html = document.documentElement;
	if (html.style.position === 'fixed') {
		html.style.position = 'static';

		const top = -parseInt(html.style.top);
		const left = -parseInt(html.style.left);

		html.scrollTop = top;
		html.scrollLeft = left;

		document.body.scrollTop = top;
		document.body.scrollLeft = left;

		html.style.width = '';
		html.style.height = '';
		html.style.position = '';
		html.style.top = '';
		html.style.left = '';
		html.style.overflowX = '';
		html.style.overflowY = '';
	}

	document.body.style.overflow = 'auto';
};

export const scrollWindowUp = targetDomId => {
	const element = targetDomId ? document.getElementById(targetDomId) : null;
	let topPos;
	if (element) {
		const heightOfNavbar = sizes.navbar.sm.default;
		topPos = element.offsetTop - heightOfNavbar - element.offsetHeight;
	} else {
		topPos = 0;
	}

	const scrollToTarget = () => window.scrollTo({top: topPos, left: 0, behavior: 'smooth'});
	scrollToTarget();
	// in a previous version we did this, which I don't want to delete for the time being:
	// const currentScrollY = window.scrollY;
	// if (withEffectIfAlreadyOnThePosition && topPos === currentScrollY) {
	// 	// we show a 'slight' scroll movement, to have 'any' effect
	// 	window.scrollTo({top: topPos - 10, left: 0, behavior: 'smooth'});
	// 	setTimeout(scrollToTarget, 200);
	// } else {
	// 	scrollToTarget();
	// }
};
