import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';
import Link from '../../Elements/Link/Link';

export const MenuFooterStyled = styled.nav`
	display: block;
	margin-top: ${withUnit(sizes.distance.base / 2)};

	${media.xl`
	    display: flex;
	`}
	${media.xxl`
		margin-top: 0;
		margin-left: ${withUnit(sizes.distance.base / 2)};
	`}
`;

export const MenuItemsStyled = styled.ul`
	display: flex;
	margin-left: -${withUnit(sizes.distance.base / 2)};
	margin-right: -${withUnit(sizes.distance.base / 2)};

	flex-direction: column;

	${media.xl`
		flex-direction: row;
	`}
`;

export const MenuItemStyled = styled.li`
	display: flex;
	padding: ${withUnit(sizes.distance.base / 6)} ${withUnit(sizes.distance.base / 2)};
	justify-content: center;

	${media.sm`
		padding: ${withUnit(sizes.distance.base / 6)} ${withUnit(sizes.distance.base / 2)};
	`}
`;

export const MenuItemSpanStyled = styled.span`
	font-weight: 600;
	font-size: ${props => (props.isUpstairs ? `0.8em` : `0.7em`)};
	color: ${colors.black};
	z-index: 10;
	transition: font-size 0.3s ease;
`;

export const MenuItemLinkStyled = styled(Link)`
	font-weight: 600;
	text-decoration: none;
	position: relative;
	color: ${colors.black};
	z-index: 10;
	transition: font-size 0.3s ease;
	font-size: ${props => (props.isUpstairs ? `0.8em` : `0.7em`)};

	&::before {
		position: absolute;
		bottom: -4px;
		left: 50%;
		width: 0;
		height: 3px;
		transition: width 0.35s ease;
		background-color: ${colors.carrot};
		transform: translate(-50%, 0);
		content: '';
		z-index: -1;
	}

	&:hover,
	&:active,
	&:focus {
		color: ${colors.black};

		&::before {
			width: 100%;
		}
	}
`;
