import React from 'react';
import {FooterStyled, FooterNavigationStyled, FooterRow, HotlineStyled} from './styles';
import MenuFooterWrapper from '../MenuFooter/MenuFooterWrapper';
import LogoBiogen from '../Logo/LogoBiogen';

const Footer = () => {
	return (
		<FooterStyled className="ap-footer">
			<FooterRow>
				<FooterNavigationStyled>
					<LogoBiogen />
					<MenuFooterWrapper />
				</FooterNavigationStyled>
				<HotlineStyled />
			</FooterRow>
		</FooterStyled>
	);
};

export default Footer;
