import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';
import Link from '../../Elements/Link/Link';
import IconButton from '../../Elements/IconButton/IconButton';

export const MenuMainContent = styled.div``;

export const MenuMainStyled = styled.nav`
	display: none;

	${media.lg`
	    display: flex;
	`}
`;

export const MenuUserStyled = styled.div`
	display: none;
	border-left: 2px solid ${colors.snow};

	${media.lg`
	    display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;
	`}
`;

export const MenuItemsStyled = styled.ul`
	display: flex;
`;

export const MenuItemStyled = styled.li`
	display: flex;
	padding: 0 ${withUnit(sizes.distance.base)};

	${media.lg`
		padding: 0 ${withUnit(sizes.distance.base * 0.75)};
	`}

	${media.xl`
		padding: 0 ${withUnit(sizes.distance.base)};
	`}
`;

export const MenuItemUserStyled = styled.li`
	display: flex;
	padding: 0 ${withUnit(sizes.distance.base)};

	${media.lg`
		padding: 0 ${withUnit(sizes.distance.base * 0.75)};
	`}

	${media.xl`
		padding: 0 ${withUnit(sizes.distance.base)};
	`}

	button {
		margin: 0;
		padding: 0;
	}
`;

export const MenuItemLinkStyled = styled(Link)`
	text-align: center;
	font-weight: 600;
	text-decoration: none;
	position: relative;
	color: ${colors.black};
	z-index: 10;
	display: flex;
	transition: font-size 0.3s ease;
	font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	${(props) => (props.active ? `font-weight: 800;` : ``)};

	${media.lg`
		font-size: ${(props) => (props.upstairs ? `0.7em` : `0.6em`)};
	`}

	${media.xl`
		font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	`}

	&::before {
		position: absolute;
		bottom: -4px;
		left: 50%;
		width: 0;
		height: 3px;
		transition: width 0.35s ease;
		background-color: ${colors.carrot};

		transform: translate(-50%, 0);
		content: '';
		z-index: -1;
		${(props) => (props.active ? `width: 100%;` : ``)};
	}

	&:hover {
		color: ${colors.black};
	}

	&:hover::before {
		width: 100%;
	}
`;

export const MenuItemExternalLinkStyled = styled.a`
	font-weight: 600;
	text-decoration: none;
	position: relative;
	color: ${colors.black};
	z-index: 10;
	display: flex;
	cursor: pointer;
	transition: font-size 0.3s ease;
	font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	${(props) => (props.active ? `font-weight: 800;` : ``)};

	${media.lg`
		font-size: ${(props) => (props.upstairs ? `0.7em` : `0.6em`)};
	`}

	${media.xl`
		font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	`}

	&::before {
		position: absolute;
		bottom: -4px;
		left: 50%;
		width: 0;
		height: 3px;
		transition: width 0.35s ease;
		background-color: ${colors.carrot};

		transform: translate(-50%, 0);
		content: '';
		z-index: -1;
		${(props) => (props.active ? `width: 100%;` : ``)};
	}

	&:hover {
		color: ${colors.black};
	}

	&:hover::before {
		width: 100%;
	}
`;

export const DashboardLinkStyled = styled(Link)`
	font-weight: 600;
	text-decoration: none;
	position: relative;
	color: ${colors.black};
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: font-size 0.3s ease;
	font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	${(props) => (props.active ? `font-weight: 800;` : ``)};

	${media.lg`
		font-size: ${(props) => (props.upstairs ? `0.7em` : `0.6em`)};
	`}

	${media.xl`
		font-size: ${(props) => (props.upstairs ? `0.8em` : `0.7em`)};
	`}

	&:hover,
	&:active,
	&:focus {
		color: ${colors.black};

		.ap-icon-button {
			box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
			transform: translateY(-2px);
		}
	}
`;

export const IconStyled = styled(IconButton)`
	width: ${sizes.forms.smallButtonHeight};
	height: ${sizes.forms.smallButtonHeight};
	//todo: check what this fix was for... maybe a mobile fix
	//margin-bottom: ${withUnit(sizes.distance.base)};

	//box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8em;

	.ap-icon {
		font-size: 1rem;
	}
`;

export const TextStyled = styled.div`
	display: block;
	margin-left: 0.5em;
	line-height: 1.1;

	span {
		display: block;
		font-size: 0.7em;
		font-style: italic;
		margin: 0;
		padding: 0;
	}
`;
