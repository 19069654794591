import styled, {css} from 'styled-components';
import {fonts, sizes} from '../../../styles/variables';
import {media, orangeBorder, withUnit} from '../../../styles/utils';

export const HeadlineStyled = css`
	font-family: ${fonts.serif};
	font-weight: 500;
	line-height: 1.4;
	${props => (props.center ? `text-align: center;` : ``)};

	${media.print`
		color-adjust: exact;
  		-webkit-print-color-adjust: exact;
	`};
`;

// Used as page title
export const HeadlineStyledH1 = styled.h1`
	${HeadlineStyled};
	font-size: 1.6rem;
	margin-top: ${withUnit(sizes.distance.base / 2)};
	margin-bottom: ${withUnit(sizes.distance.base)};

	${media.md`
		font-size: 1.9rem; 
	`};

	${media.xxl`
		font-size: 2.4rem;
	`};
`;

// Used for other upper level headlines on pages
export const HeadlineStyledH2 = styled.h2`
	${HeadlineStyled};
	font-size: 1.2rem;
	margin-top: ${withUnit(sizes.distance.base / 3)};
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${media.md`
		font-size: 1.4rem;
		margin-top: ${withUnit(sizes.distance.base / 2)};
		margin-bottom: ${withUnit(sizes.distance.base)};
	`};
`;

export const HeadlineStyledH3 = styled.h3`
	position: relative;
	font-size: 1rem;
	padding-bottom: ${withUnit(sizes.distance.base / 3)};
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base / 3)};
	${props => props.direction === 'rtl' ? orangeBorder('right') : orangeBorder()}
	

	${media.md`
		font-size: 1rem;
		padding-bottom: ${withUnit(sizes.distance.base / 3)};
		margin-bottom: ${withUnit(sizes.distance.base / 2)};
	`};
`;

// Used as page subtitle
export const HeadlineStyledH4 = styled.h4`
	font-size: 1rem;
`;

export const HeadlineStyledH5 = styled.h5`
	${HeadlineStyled};
	font-size: 1.1rem;
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};
`;

export const HeadlineStyledH6 = styled.h6`
	${HeadlineStyled};
	font-size: 1rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};
`;
