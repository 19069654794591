import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import MenuFooter from './MenuFooter';

const MenuFooterWrapper = () => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer-menu"}}) {
					edges {
						node {
							name
							slug
							items {
								title
								object_slug
								path
							}
						}
					}
				}
			}
		`}
		render={(data) => <MenuFooter data={data} />}
	/>
);

export default MenuFooterWrapper;
