import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {NavbarStyled, NavbarNavigation, PageProgressWrapper, PageProgress} from './styles';
import MenuMain from '../MenuMain/MenuMain';
import LogoMSlife from '../Logo/LogoMSlife';
import Burger from '../Burger/Burger';
import SearchBtn from '../SearchBtn/SearchBtn';

const Navbar = ({isUpstairs, activeSlug, handleBurgerItemClick}) => {
	const [scrollPos, setScrollPos] = useState(0);

	const progressBar = () => {
		const scrollTotal = document.documentElement.scrollTop;
		const heightWin = document.documentElement.scrollHeight - document.documentElement.clientHeight;
		const scroll = `${(scrollTotal / heightWin) * 100}%`;

		setScrollPos(scroll);
	};

	useEffect(() => {
		window.addEventListener('scroll', progressBar);
	}, []);

	return (
		<>
			<NavbarStyled upstairs={isUpstairs}>
				<LogoMSlife upstairs={isUpstairs} />
				<NavbarNavigation>
					<MenuMain upstairs={isUpstairs} activeSlug={activeSlug} />
					<SearchBtn />
					<Burger onClick={handleBurgerItemClick} />
				</NavbarNavigation>
			</NavbarStyled>
			<PageProgressWrapper upstairs={isUpstairs}>
				<PageProgress width={scrollPos} />
			</PageProgressWrapper>
		</>
	);
};

const areEqual = (prevProps, nextProps) => {
	if (prevProps.isUpstairs === nextProps.isUpstairs) {
		return true;
	}
	return false;
};

Navbar.propTypes = {
	isUpstairs: PropTypes.bool,
	activeSlug: PropTypes.string.isRequired,
	handleBurgerItemClick: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
	isUpstairs: false,
};

/** @component */
export default React.memo(Navbar, areEqual);
