import styled from 'styled-components';
import {media} from '../../../styles/utils';
import Link from '../../Elements/Link/Link';

export const LinkStyled = styled(Link)`
	display: inline-flex;

	${media.print`
		display: inline-block;
	`};
`;

export const MSlifeLogoStyled = styled.img`
	z-index: 1002;
	transition: height 0.25s;
	height: ${props => (props.upstairs ? `40px` : `30px`)};
	width: ${props => (props.upstairs ? `89px` : `67px`)};

	${media.lg`
		height: ${props => (props.upstairs ? `50px` : `40px`)};
		width: ${props => (props.upstairs ? `111px` : `89px`)};
	`}
`;

export const CleoLogoStyled = styled.img`
	height: auto;
	width: 200px;

	${media.lg`
		width: 300px;
		height: auto;
	`}
`;

export const CommunityLogoStyled = styled.img`
	height: 80px;
	width: 250px;

	${media.lg`
		height: 140px;
		width: 436px;
	`}
`;

export const LogoBiogenStyled = styled.img`
	height: 40px;
	width: 119px;
`;

export const ServiceLogoStyled = styled.img`
	
	height: 75px;
	width: 75px;

	${media.sm`
	height: 115px;
	width: 115px;
	`}

	${media.lg`
	height: 150px;
	width: 150px;
	`}
`;
