import {connect} from 'react-redux';

import {hideDisclaimerExternalLink} from '../../../state/actions/disclaimer';
import {KEY_TO} from '../../../state/reducers/disclaimer/constants';
import DisclaimerExternalLink from './DisclaimerExternalLink';

const mapStateToProps = state => ({
	to: state.disclaimer && state.disclaimer[KEY_TO]
});

const mapDispatchToProps = dispatch => ({
	onRequestToHide: () => dispatch(hideDisclaimerExternalLink())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DisclaimerExternalLink);
