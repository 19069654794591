import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Location} from '@reach/router';
import {Link as GatsbyLink} from 'gatsby';
import {isInternalDestination, isDownload} from '../../../utils/urls';

const Link = (props) => {
	const {children, to, user, state, ...other} = props;

	// <GatsbyLink> for internal links,
	// <a> for external links, and for 'sibling' destinations, i.e. *.ms-life.de, *biogen*,

	// This assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash or includes the hostname,
	// and that anything else is external or sibling host.

	if (to === undefined || to === null) {
		// cases, where to is not set - eg styled links in search on the left pane
		if (other.isSearchAnchorLink) {
			return (
				<a href={to} {...other} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			);
		}
		// something's wrong - avoid app crash
		return <>{children}</>;
	}

	if (to && isDownload(to)) {
		return (
			<a href={to} {...other} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	}

	if (to.indexOf('mailto:') > -1) {
		return (
			<a href={to} {...other}>
				{children}
			</a>
		);
	}

	if (to === '/community') {
		return user?.Agreement ? (
			<a href={to} {...other}>
				{children}
			</a>
		) : (
			<GatsbyLink to="/datenschutz" state={{to: '/community'}} {...other}>
				{children}
			</GatsbyLink>
		);
	}

	if (to !== '/ms-community/' && isInternalDestination(to)) {
		return (
			<Location>
				{({location}) => (
					<GatsbyLink to={to} {...other} state={{prevUrlPath: location.pathname, ...state}}>
						{children}
					</GatsbyLink>
				)}
			</Location>
		);
	}

	// we don't test for external or sibling destinations here (re: disclaimer for external links),
	// because that is handled globally by <ObserveExternalLinks>
	return (
		<a href={to} {...other} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};

Link.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
	to: PropTypes.string,
	state: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
	user: PropTypes.shape({FirstName: PropTypes.string, Agreement: [PropTypes.bool, PropTypes.string]}).isRequired,
};

Link.defaultProps = {
	children: null,
	to: null,
	state: null,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(Link);
