import {connect} from 'react-redux';
import {navigate} from 'gatsby';
import SearchForm from '../../../Container/Search/SearchForm/SearchForm';
import {SEARCH_PATH} from '../../../../routes/constants';
import {initialSearch} from '../../../../state/actions/search';

const mapStateToProps = state => ({
	searchTerm: state.initialSearch.searchTerm
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSearchRequest: searchString => {
		// 1. call props.onSearchRequest if it exists
		if (ownProps.onSearchRequest) {
			ownProps.onSearchRequest();
		}
		// 2. go to SEARCH_PATH
		navigate(SEARCH_PATH);
		// 3. dispatch search action
		dispatch(initialSearch(searchString));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchForm);
