import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {userLogout} from '../../../../state/actions/userManagement';

import {Icon} from '../../../Elements/Icon/Icon';

import {LogoutStyled} from './styles';
import IconButton from '../../../Elements/IconButton/IconButton';

const LogoutButton = ({text, handleLogout}) => {
	return (
		<LogoutStyled onClick={() => handleLogout()}>
			<IconButton text={text}>
				<Icon name="logout" />
			</IconButton>
		</LogoutStyled>
	);
};

const mapDispatchToProps = dispatch => ({
	handleLogout: () => dispatch(userLogout())
});

export default connect(
	null,
	mapDispatchToProps
)(LogoutButton);

LogoutButton.propTypes = {
	text: PropTypes.string,
	handleLogout: PropTypes.func
};

LogoutButton.defaultProps = {
	text: '',
	handleLogout: null
};
