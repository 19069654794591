import React from 'react/index';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {SearchFormStyled, SearchInputStyled, SearchIconStyled} from './styles';

class SearchForm extends React.PureComponent {
	deferredSearch = _.debounce(() => this.performSearch(false), 1000); // only used, when props.autoSubmit === true

	constructor(props) {
		super(props);
		const {isOpen, searchTerm} = props;

		this.state = {
			isOpen, // todo: what happens, if prop isOpen is changed after initialization, e.g. after the component has been mounted?
			searchTerm: searchTerm || ''
		};
	}

	// react lifecycle methods

	componentDidUpdate(prevProps) {
		// if this.props.searchTermin is changed, we set this.state.searchTerm accordingly
		const {searchTerm} = this.props;
		if (searchTerm !== prevProps.searchTerm) {
			this.setState({searchTerm});
		}
	}

	handleButtonClick = () => {
		const {isPermanent} = this.props;
		const {isOpen} = this.state;

		if (!isPermanent) {
			this.setState({
				isOpen: !isOpen
			});

			// todo: focus input
		}

		this.performSearch();
	};

	handleKeyPress = event => {
		// handle ENTER key
		if (event.key === 'Enter') {
			this.performSearch();
		}
	};

	handleInputChange = event => {
		const {autoSubmit} = this.props;
		this.setState({searchTerm: event.target.value});
		if (autoSubmit) {
			this.deferredSearch();
		}
	};

	performSearch(cancelDeferred = true) {
		const {isOpen, searchTerm} = this.state;
		const {autoSubmit, onSearchRequest} = this.props;
		if (isOpen) {
			if (autoSubmit && cancelDeferred) {
				this.deferredSearch.cancel();
			}
			onSearchRequest(searchTerm);
		}
	}

	render() {
		const {fullWidth, isPermanent, placeholder, className} = this.props;
		const {isOpen, searchTerm} = this.state;
		const searchFormProps = {
			isPermanent,
			isOpen,
			fullWidth
		};
		return (
			<SearchFormStyled className={className} {...searchFormProps}>
				<SearchInputStyled
					{...searchFormProps}
					onChange={this.handleInputChange}
					onKeyPress={this.handleKeyPress}
					placeholder={placeholder}
					value={searchTerm}
				/>
				<SearchIconStyled {...searchFormProps} name="search" onClick={this.handleButtonClick} />
			</SearchFormStyled>
		);
	}
}

SearchForm.propTypes = {
	autoSubmit: PropTypes.bool,
	isPermanent: PropTypes.bool,
	isOpen: PropTypes.bool,
	fullWidth: PropTypes.bool,
	onSearchRequest: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	searchTerm: PropTypes.string
};

SearchForm.defaultProps = {
	autoSubmit: true,
	isPermanent: true,
	isOpen: true,
	fullWidth: false,
	placeholder: 'Wonach suchst du?',
	className: 'ap-searchform',
	onSearchRequest: () => {},
	searchTerm: null
};

/** @component */
export default SearchForm;
