import {css, createGlobalStyle} from 'styled-components';
import {colors, fonts, sizes} from './variables';
import {withUnit, media, gradientCandyCarrot} from './utils';

const fontStyles = css`
	color: ${colors.black};
	font-family: ${fonts.sans_serif};
	font-weight: 300;
	line-height: 1.5;
`;

const fontSizes = css`
	font-size: ${withUnit(sizes.fonts.base - 4)};

	${media.sm`
        font-size: ${withUnit(sizes.fonts.base - 2)};				
    `};

	${media.md`
        font-size: ${withUnit(sizes.fonts.base)};				
    `};
`;

// language=SCSS
export const GlobalStyle = createGlobalStyle`
	// General styles
	html,
	body {
		margin: 0;
		padding: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		${fontSizes};
		${fontStyles};
	}
	
	input,
	textarea {
		${fontStyles};
		font-size: 1rem;
	}
	
	a {
		text-decoration: none;
		color: ${colors.carrot};
		font-weight: 600;
		
		&:hover,
		&:active,
		&:focus {
			color: ${colors.candy};
		}
	}
	
	p {
		margin-top: 0;
	}
	
	// List reset
	ul {
	  margin: 0;
	  padding: 0;
	  list-style: none;
	}
	
	hr {
		box-sizing: content-box;
		height: 0;
		border: 0;
		border-top: 1px solid rgba(0,0,0,.1);
		margin: 3em 0;
	}

	.Modal {
		position: relative;
		background: white;
		z-index: 1001;
		max-width: 1024px;
		margin: 0;
		padding: 1rem;
		box-shadow: 2px 2px 16px black;
		&:focus {
			outline: none;
		}
		&:active {
			outline: none;
		}
		${media.md`
		margin: 2rem auto;
		padding: 2rem;
		`};
	}

	.Overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		z-index: 1000;
	}
	
	#cookiebanner[style] {
	  background-color: ${colors.carrot};
	  ${gradientCandyCarrot()};
	
	  #wrapper {
		display: flex;
		align-items: center;
	
		p [style] {
		  color: ${colors.white};
		  font-family: ${fonts.sans_serif};
		  font-size: 14px!important;
		}
	
		#cookiesLink {
		  font-weight: 600;
		}
		
		.c-left {
			float: none;
			max-width: none;
		}
	
		.c-right {
			margin-top: 0;
		}

		.c-message {
			color: ${colors.white};
		}
	
		a.c-button {
			width: auto;
			display: inline-flex;
			align-items: center;
			height: ${sizes.forms.buttonHeight};
			border-radius: ${sizes.borderRadius};
			background: ${colors.white};
			color: ${colors.black};
			font-family: ${fonts.sans_serif} !important;
			box-sizing:border-box;
			font-size: 1rem;
			font-weight: 600;
			text-align: center;
			text-decoration: none;
			line-height: 1;
			will-change: background-color, background-position, transform, font-size, box-shadow;
			transition: all 0.3s ease-out;
			
			&:not([disabled]):hover,
			&:not([disabled]):focus {
					background: ${colors.almostwhite};
				transform: translateY(-3px);
				box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
				cursor: pointer;
				outline: 0;
			}
		}
	  }
	}

`;

export const GlobalListStyle = css`
	color: ${colors.black};
	font-family: ${fonts.sans_serif};
	font-weight: 300;
	font-size: 1em;
	margin-bottom: 1.5em;
	padding-left: 0;
	list-style: none;

	li {
		position: relative;
		padding-left: ${withUnit(sizes.distance.base * 1.4)};
		margin-bottom: ${withUnit(sizes.distance.base / 3)};

		&::before {
			position: absolute;
			flex: none;
			content: '';
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.carrot};
			color: ${colors.white};
			font-weight: 700;
			font-size: 0.75em;
			border-radius: 100%;
			width: 12px;
			height: 12px;
			left: ${withUnit(sizes.distance.base / 2)};
			top: 11px;
		}
	}

	ul {
		margin-top: ${withUnit(sizes.distance.base / 3)};

		li {
			&::before {
				width: ${withUnit(sizes.distance.base / 3)};
				height: ${withUnit(sizes.distance.base / 3)};
				left: ${withUnit(sizes.distance.base / 1.4)};
				background-color: ${colors.carrot};
			}
		}
	}
`;

export const GlobalOrderedListStyle = css`
	counter-reset: list-ordered;
	font-weight: 300;
	font-size: 1em;
	margin-bottom: 1.5em;
	margin-left: 0;
	padding-left: 0;
	list-style: none;

	li {
		counter-increment: list-ordered;
		position: relative;
		padding-left: ${withUnit(sizes.distance.base)};
		margin-bottom: ${withUnit(sizes.distance.base / 3)};

		&::before {
			position: absolute;
			flex: none;
			content: counter(list-ordered, ${(props) => (props.type ? props.type : 'decimal')});
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.carrot};
			color: ${colors.white};
			font-weight: 700;
			font-size: 0.75em;
			border-radius: 50%;
			width: ${withUnit(sizes.distance.base * 0.75)};
			height: ${withUnit(sizes.distance.base * 0.75)};
			line-height: ${withUnit(sizes.distance.base * 0.75)};
			left: 0;
			top: 1px;
		}
	}
`;

export const GlobalFormStyle = css`
	margin: 0 auto ${withUnit(sizes.distance.base)} auto;

	${media.sm`
		width: 80vw;
	`};

	${media.lg`
		width: 60vw;
	`};
`;
