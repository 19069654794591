import React from 'react';

import {navigate} from 'gatsby';
import {SearchBtnStyled, SearchBtnBoxStyled, SearchBtnIconStyled} from './styles';

const SearchBtn = () => (
		<SearchBtnStyled onClick={()=> navigate('/suche')}>
			
            <SearchBtnIconStyled  name="search" />
			
		</SearchBtnStyled>
	);




export default SearchBtn;
