import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import BurgerMenu from './BurgerMenu';

const BurgerMenuWrapper = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpApiMenusMenusItems {
					edges {
						node {
							id
							name
							items {
								path
								title
								object_slug
								type
								url
								wordpress_children {
									title
									path
									object_slug
									type
									url
									wordpress_children {
										title
										path
										object_slug
										type
										url
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <BurgerMenu data={data} {...props} />}
	/>
);

export default BurgerMenuWrapper;
