import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';
import Link from '../../Elements/Link/Link';

export const HotlineStyled = styled.div`
	// todo: fix this in another way as it breaks the usage inside footer element
	// ${props => (props.isIE ? 'position: absolute; bottom: 2em;' : '')}
	
	.ap-burger-menu & {
		margin-top: ${withUnit(sizes.distance.base)};
	}

	.ap-footer & {
		font-size: 1.05rem;
		margin: ${withUnit(sizes.distance.base / 2)} 0;

		strong {
			display: block;
			font-weight: 600;

			${media.md`
				display: inline;
			`};
		}
	}
`;

export const PhoneNumberStyled = styled.a`
	display: block;
	
	${media.md`
		display: inline;
	`};

	color: ${colors.carrot};
	font-weight: 700;
	white-space: nowrap;

	.ap-burger-menu & {
		display: block;
		font-size: 1.25em;
	}
`;

export const VideoLineStyled = styled(Link)`
	color: ${colors.carrot};
	font-weight: 700;
	white-space: nowrap;

	.ap-burger-menu & {
		font-size: 1.25em;
	}
`;
