const formselectdata = {
	medium: [
		{
			uid: '1',
			medium_name: 'Arzt / Ärztin'
		},
		{
			uid: '2',
			medium_name: 'Praxispersonal / MS Nurse'
		},
		{
			uid: '3',
			medium_name: 'Apotheke'
		},
		{
			uid: '4',
			medium_name: 'Freunde / Bekannte / Kollegen / andere Betroffene'
		},
		{
			uid: '5',
			medium_name: 'Informationsmaterial / Flyer'
		},
		{
			uid: '7',
			medium_name: 'MS Service-Center / GEMEINSAM STARK Begleitprogramm'
		},
		{
			uid: '8',
			medium_name: 'Zeitschrift / Zeitung'
		},
		{
			uid: '9',
			medium_name: 'Internet'
		},
		{
			uid: '10',
			medium_name: 'Veranstaltungen'
		},
		{
			uid: '11',
			medium_name: 'Medikamentenpackung'
		},
		{
			uid: '14',
			medium_name: 'keine Angabe'
		}
	],
	state: [
		{
			uid: '1',
			state_name: 'Baden-Württemberg'
		},
		{
			uid: '2',
			state_name: 'Bayern'
		},
		{
			uid: '3',
			state_name: 'Berlin'
		},
		{
			uid: '4',
			state_name: 'Brandenburg'
		},
		{
			uid: '5',
			state_name: 'Bremen'
		},
		{
			uid: '6',
			state_name: 'Hamburg'
		},
		{
			uid: '7',
			state_name: 'Hessen'
		},
		{
			uid: '8',
			state_name: 'Mecklenburg-Vorpommern'
		},
		{
			uid: '9',
			state_name: 'Niedersachsen'
		},
		{
			uid: '10',
			state_name: 'Nordrhein-Westfalen'
		},
		{
			uid: '11',
			state_name: 'Rheinland-Pfalz'
		},
		{
			uid: '12',
			state_name: 'Saarland'
		},
		{
			uid: '13',
			state_name: 'Sachsen'
		},
		{
			uid: '14',
			state_name: 'Sachsen-Anhalt'
		},
		{
			uid: '15',
			state_name: 'Schleswig-Holstein'
		},
		{
			uid: '16',
			state_name: 'Thüringen'
		}
	],
	title: [
		{
			uid: '2',
			title_name: 'Dr.'
		},
		{
			uid: '3',
			title_name: 'Dr.med.'
		},
		{
			uid: '6',
			title_name: 'Professor Dr.'
		}
	],
	country: [
		{uid: '1', country_name: 'Afghanistan'},
		{uid: '52', country_name: 'Ägypten'},
		{uid: '2', country_name: 'Albanien'},
		{uid: '3', country_name: 'Algerien'},
		{uid: '4', country_name: 'Andorra'},
		{uid: '5', country_name: 'Angola'},
		{uid: '6', country_name: 'Antigua und Barbuda'},
		{uid: '54', country_name: 'Äquatorial-Guinea'},
		{uid: '7', country_name: 'Argentinien'},
		{uid: '8', country_name: 'Armenien'},
		{uid: '11', country_name: 'Aserbaidschan'},
		{uid: '57', country_name: 'Äthiopien'},
		{uid: '9', country_name: 'Australien'},
		{uid: '12', country_name: 'Bahamas'},
		{uid: '13', country_name: 'Bahrain'},
		{uid: '14', country_name: 'Bangladesch'},
		{uid: '15', country_name: 'Barbados'},
		{uid: '17', country_name: 'Belgien'},
		{uid: '18', country_name: 'Belize'},
		{uid: '19', country_name: 'Benin'},
		{uid: '20', country_name: 'Bhutan'},
		{uid: '21', country_name: 'Bolivien'},
		{uid: '22', country_name: 'Bosnien-Herzegowina'},
		{uid: '23', country_name: 'Botswana'},
		{uid: '24', country_name: 'Brasilien'},
		{uid: '25', country_name: 'Brunei Darussalam'},
		{uid: '26', country_name: 'Bulgarien'},
		{uid: '27', country_name: 'Burkina Faso'},
		{uid: '28', country_name: 'Burma'},
		{uid: '29', country_name: 'Burundi'},
		{uid: '36', country_name: 'Chile'},
		{uid: '37', country_name: 'China (Volksrepublik)'},
		{uid: '41', country_name: 'Costa Rica'},
		{uid: '47', country_name: 'Dänemark'},
		{uid: '64', country_name: 'Deutschland'},
		{uid: '49', country_name: 'Dominica'},
		{uid: '50', country_name: 'Dominikanische Republik'},
		{uid: '48', country_name: 'Dschibuti'},
		{uid: '51', country_name: 'Ecuador'},
		{uid: '53', country_name: 'El Salvador'},
		{uid: '42', country_name: 'Elfenbeinküste'},
		{uid: '55', country_name: 'Eritrea'},
		{uid: '56', country_name: 'Estland'},
		{uid: '58', country_name: 'Fidschi'},
		{uid: '59', country_name: 'Finnland'},
		{uid: '60', country_name: 'Frankreich'},
		{uid: '61', country_name: 'Gabun'},
		{uid: '62', country_name: 'Gambia'},
		{uid: '63', country_name: 'Georgien'},
		{uid: '65', country_name: 'Ghana'},
		{uid: '67', country_name: 'Grenada'},
		{uid: '66', country_name: 'Griechenland'},
		{uid: '68', country_name: 'Guatemala'},
		{uid: '69', country_name: 'Guinea'},
		{uid: '70', country_name: 'Guinea-Bissau'},
		{uid: '71', country_name: 'Guyana'},
		{uid: '72', country_name: 'Haiti'},
		{uid: '74', country_name: 'Honduras'},
		{uid: '77', country_name: 'Indien'},
		{uid: '78', country_name: 'Indonesien'},
		{uid: '80', country_name: 'Irak'},
		{uid: '79', country_name: 'Iran'},
		{uid: '81', country_name: 'Irland'},
		{uid: '76', country_name: 'Island'},
		{uid: '82', country_name: 'Israel'},
		{uid: '83', country_name: 'Italien'},
		{uid: '84', country_name: 'Jamaika'},
		{uid: '85', country_name: 'Japan'},
		{uid: '187', country_name: 'Jemen'},
		{uid: '86', country_name: 'Jordanien'},
		{uid: '30', country_name: 'Kambodscha'},
		{uid: '31', country_name: 'Kamerun'},
		{uid: '32', country_name: 'Kanada'},
		{uid: '33', country_name: 'Kap Verde'},
		{uid: '87', country_name: 'Kasachstan'},
		{uid: '139', country_name: 'Katar'},
		{uid: '88', country_name: 'Kenia'},
		{uid: '93', country_name: 'Kirgisistan'},
		{uid: '89', country_name: 'Kiribati'},
		{uid: '38', country_name: 'Kolumbien'},
		{uid: '39', country_name: 'Komoren'},
		{uid: '40', country_name: 'Kongo'},
		{uid: '90', country_name: 'Korea (Nord)'},
		{uid: '91', country_name: 'Korea (Süd)'},
		{uid: '43', country_name: 'Kroatien'},
		{uid: '44', country_name: 'Kuba'},
		{uid: '92', country_name: 'Kuwait'},
		{uid: '94', country_name: 'Laos'},
		{uid: '97', country_name: 'Lesotho'},
		{uid: '95', country_name: 'Lettland'},
		{uid: '96', country_name: 'Libanon'},
		{uid: '98', country_name: 'Liberia'},
		{uid: '99', country_name: 'Libyen'},
		{uid: '100', country_name: 'Liechtenstein'},
		{uid: '101', country_name: 'Litauen'},
		{uid: '102', country_name: 'Luxemburg'},
		{uid: '104', country_name: 'Madagaskar'},
		{uid: '105', country_name: 'Malawi'},
		{uid: '106', country_name: 'Malaysia'},
		{uid: '107', country_name: 'Malediven'},
		{uid: '108', country_name: 'Mali'},
		{uid: '109', country_name: 'Malta'},
		{uid: '118', country_name: 'Marokko'},
		{uid: '110', country_name: 'Marshallinseln'},
		{uid: '111', country_name: 'Mauretanien'},
		{uid: '112', country_name: 'Mauritius'},
		{uid: '103', country_name: 'Mazedonien'},
		{uid: '113', country_name: 'Mexiko'},
		{uid: '114', country_name: 'Mikronesien'},
		{uid: '115', country_name: 'Moldavien'},
		{uid: '116', country_name: 'Monaco'},
		{uid: '117', country_name: 'Mongolei'},
		{uid: '119', country_name: 'Mosambik'},
		{uid: '120', country_name: 'Namibia'},
		{uid: '121', country_name: 'Nauru'},
		{uid: '122', country_name: 'Nepal'},
		{uid: '124', country_name: 'Neuseeland'},
		{uid: '125', country_name: 'Nicaragua'},
		{uid: '123', country_name: 'Niederlande'},
		{uid: '126', country_name: 'Niger'},
		{uid: '127', country_name: 'Nigeria'},
		{uid: '128', country_name: 'Norwegen'},
		{uid: '129', country_name: 'Oman'},
		{uid: '10', country_name: 'Österreich'},
		{uid: '130', country_name: 'Pakistan'},
		{uid: '131', country_name: 'Palau'},
		{uid: '132', country_name: 'Panama'},
		{uid: '133', country_name: 'Papua-Neuguinea'},
		{uid: '134', country_name: 'Paraguay'},
		{uid: '135', country_name: 'Peru'},
		{uid: '136', country_name: 'Philippinen'},
		{uid: '137', country_name: 'Polen'},
		{uid: '138', country_name: 'Portugal'},
		{uid: '142', country_name: 'Ruanda'},
		{uid: '140', country_name: 'Rumänien'},
		{uid: '141', country_name: 'Russland (Russische Föderation)'},
		{uid: '143', country_name: 'Saint Kitts and Nevis'},
		{uid: '144', country_name: 'Saint Lucia'},
		{uid: '145', country_name: 'Saint Vincent und die Grenadinen'},
		{uid: '156', country_name: 'Salomonen'},
		{uid: '188', country_name: 'Sambia'},
		{uid: '146', country_name: 'Samoa'},
		{uid: '147', country_name: 'San Marino'},
		{uid: '148', country_name: 'Sao Tome und Principe'},
		{uid: '149', country_name: 'Saudi-Arabien'},
		{uid: '164', country_name: 'Schweden'},
		{uid: '165', country_name: 'Schweiz'},
		{uid: '150', country_name: 'Senegal'},
		{uid: '151', country_name: 'Seyschellen'},
		{uid: '152', country_name: 'Sierra Leone'},
		{uid: '153', country_name: 'Singapur'},
		{uid: '154', country_name: 'Slowakei'},
		{uid: '155', country_name: 'Slowenien'},
		{uid: '157', country_name: 'Somalia'},
		{uid: '159', country_name: 'Spanien'},
		{uid: '160', country_name: 'Sri Lanka'},
		{uid: '158', country_name: 'Südafrika'},
		{uid: '161', country_name: 'Sudan'},
		{uid: '162', country_name: 'Suriname'},
		{uid: '163', country_name: 'Swasiland'},
		{uid: '166', country_name: 'Syrien'},
		{uid: '167', country_name: 'Tadschikistan'},
		{uid: '168', country_name: 'Tansania'},
		{uid: '169', country_name: 'Thailand'},
		{uid: '170', country_name: 'Togo'},
		{uid: '171', country_name: 'Tonga'},
		{uid: '172', country_name: 'Trinidad und Tobago'},
		{uid: '35', country_name: 'Tschad'},
		{uid: '46', country_name: 'Tschechien'},
		{uid: '173', country_name: 'Tunesien'},
		{uid: '174', country_name: 'Türkei'},
		{uid: '175', country_name: 'Turkmenistan'},
		{uid: '176', country_name: 'Tuvalu'},
		{uid: '177', country_name: 'Uganda'},
		{uid: '178', country_name: 'Ukraine'},
		{uid: '75', country_name: 'Ungarn'},
		{uid: '180', country_name: 'United Kindom (Großbritannien)'},
		{uid: '182', country_name: 'Uruguay'},
		{uid: '183', country_name: 'Usbekistan'},
		{uid: '184', country_name: 'Vanuatu'},
		{uid: '73', country_name: 'Vatikanstadt'},
		{uid: '185', country_name: 'Venezuela'},
		{uid: '179', country_name: 'Vereinigte Arabische Emirate'},
		{uid: '181', country_name: 'Vereinigte Staaten (USA)'},
		{uid: '186', country_name: 'Vietnam'},
		{uid: '16', country_name: 'Weißrussland (Belarus)'},
		{uid: '34', country_name: 'Zentralafrikanische Republik'},
		{uid: '189', country_name: 'Zimbabwe'},
		{uid: '45', country_name: 'Zypern'}
	]
};

module.exports = formselectdata;
