/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
export const GTMID = process.env.GOOGLE_TAG_MANAGER_ID;

const biibSite = {
	therapyarea: '',
	region: '',
	stgURL: '',
	prdURL: '',
	isActive: '',
	assetCode: '',
	cookieBotID: '63c3fb9e-3fd9-4074-a554-eb1070c6bf55',
};

const getDate = () => {
	return new Date().toJSON().slice(0, 10).replace(/-/g, '');
};

// Retrieve the data stored in the Cookie
const gaGetCookie = (cname) => {
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

// Set Cookie
const gaSetCookie = (cname, cvalue, exdays) => {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

// Check if the Cookie Exists
const gaCheckCookie = (cname) => {
	var cookieValue = gaGetCookie(cname);
	if (cookieValue.length > 0) {
		return true;
	} else {
		return false;
	}
};

// Retrieve the number of pageviews in the session
const visitorSessionPagesViewed = () => {
	var pageViews = 0;

	if (gaCheckCookie('gaPageViews')) {
		pageViews = gaGetCookie('gaPageViews');

		if (isNaN(pageViews)) {
			gaSetCookie('gaPageViews', '1', 365);
			return 1;
		} else {
			gaSetCookie('gaPageViews', ++pageViews, 365);
			return pageViews;
		}
	} else {
		gaSetCookie('gaPageViews', '1', 365);
		return 1;
	}
};

const getConsentStatus = () => {
	try {
		var cookieBotStatus = gaCheckCookie('CookieConsent');
		var retCookieConsent = false;
		var pageViewsSession = visitorSessionPagesViewed();
		if (cookieBotStatus != '') retCookieConsent = true;

		if (retCookieConsent) {
			return retCookieConsent + '|' + getDate();
		} else {
			if (pageViewsSession > 1) return 'true|' + getDate();
			else return 'false';
		}
	} catch (err) {
		return 'false';
	}
};

// Return an array of objects according to key, value, or key and value matching
const getObjects = (obj, key, val) => {
	var objects = [];

	for (var i in obj) {
		if (!obj.hasOwnProperty(i)) continue;

		if (typeof obj[i] == 'object') {
			objects = objects.concat(getObjects(obj[i], key, val));
		}
		// if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
		else if ((i == key && obj[i] == val) || (i == key && val == '')) {
			objects.push(obj);
		} else if (obj[i] == val && key == '') {
			// only add if the object is not already in the array
			if (objects.lastIndexOf(obj) == -1) {
				objects.push(obj);
			}
		}
	}
	return objects;
};

const getSiteLanguage = () => {
	return 'de';
};

const getCountry = () => {
	return 'de';
};

// TODO: pass visitor id
// const getVisitorID = () => {
// 	return '';
// };

const getPageName = (props) => {
	let pageName = '';
	if (props.trackingProps && props.trackingProps.title) {
		pageName = props.trackingProps.title;
	}
	if (pageName === '') {
		pageName = document.title;
	}
	return pageName;
};

const getSiteName = () => {
	return 'Mein MSLife';
};

const getContentID = (props) => {
	let contentID = 0;
	if (props.trackingProps && props.trackingProps.wordpressId) {
		contentID = props.trackingProps.wordpressId;
	}
	return contentID;
};

const getEnvironment = () => {
	let host = '';

	switch (window.location.hostname) {
		case 'web833.dev71.antwerpes.de':
			host = 'dev';
			break;
		case 'stg-mein.ms-life.de':
			host = 'stg';
			break;
		case 'mein.ms-life.de':
			host = 'prod';
			break;
		default:
			host = 'test';
	}

	return host;
};

const getLoginStatus = (user) => {
	if (user == undefined) return 'anonymous';
	return 'logged';
};

// Retrieve the Site Section
// const getSection = (level, name) => {
// 	if (level === 1) {
// 		return 'home';
// 	}
// 	return '';
// };

// helper functions
const categoryMapperLevel1 = {
	'/': 'home',
	'/kontakt': 'contact',
	'/service': 'tools',
	'/multiple-sklerose': 'content',
	'/leben-mit-ms': 'listing',
	'/impressum': 'other',
	'/cookies': 'other',
	'/hilfe': 'other',
	'/datenschutzerklaerung': 'legal',
	'/suche': 'suche',
};

const cutTrailingSlash = (pathname) => {
	let replacedPath = '';
	// this is root - exit
	if (pathname === '/') {
		replacedPath = pathname;
		return replacedPath;
	}
	replacedPath = pathname.charAt(pathname.length - 1) === '/' ? pathname.substr(0, pathname.length - 1) : pathname;
	return replacedPath;
};

const getSectionLevel = (pathname) => {
	const replacedPathname = cutTrailingSlash(pathname);
	let level = 1;
	if (replacedPathname.indexOf('/') !== -1) {
		level = replacedPathname.match(new RegExp('/', 'g') || []).length;
		if (level === 1 && categoryMapperLevel1[replacedPathname]) {
			return categoryMapperLevel1[replacedPathname];
		}
	}
	return '';
};

export const getJSONConfig = () => {
	return fetch(`https://aem-static-files.s3.eu-central-1.amazonaws.com/gtm/gtm_variables.json?no-cache=${getDate()}`)
		.then((response) => {
			return response.json();
		})
		.then(() => {
			// console.log(json);
		});
};

const getAsyncJSONConfig = async () => {
	const response = await fetch(
		`https://aem-static-files.s3.eu-central-1.amazonaws.com/gtm/gtm_variables.json?no-cache=${getDate()}`
	);
	const json = await response.json();
	return json;
};

export const initTracking = async (props) => {
	if (typeof window !== 'object') return;

	const response = await getAsyncJSONConfig();
	const sectionLevel = getSectionLevel(props.location.pathname);
	const pageName = getPageName(props);
	const contentID = getContentID(props);
	const loginStatus = getLoginStatus(props.user);
	// const hostname = 'mein.ms-life.de';
	const {hostname} = window.location;
	const site = getObjects(response, '', hostname);

	window.dataLayer = window.dataLayer || [];
	if (site.length > 0) {
		biibSite.therapyarea = site[0].therapyarea;
		biibSite.region = site[0].biib_region;
		biibSite.stgURL = site[0].stg;
		biibSite.prdURL = site[0].prd;
		biibSite.devURL = site[0].dev;
		biibSite.isActive = site[0].active;
		biibSite.assetCode = site[0].biib_asset_code;
		biibSite.cookieBotID = site[0].cookieBotID;

		dataLayer.push({spaWebsite: 'true'});

		// Google Tag Manager DataLayer : SITE DETAILS
		dataLayer.push({
			biib_asset_code: biibSite.assetCode, // Asset code of the website/App - from the excel table accordingly
			biib_region: biibSite.region, // World Region
			therapyArea: biibSite.therapyarea, // Therapeutic Area
		});
		// Google Tag Manager DataLayer : DIGITAL ASSET
		dataLayer.push({
			biib_name: getSiteName(), // Name of the Website/App
			biib_language: getSiteLanguage(), // Program Language
			biib_country: getCountry(), // Country of the website
			biib_env: getEnvironment(), // Production environnent
		});
		// Google Tag Manager DataLayer : DIGITAL CONTENT
		dataLayer.push({
			contentName: pageName,
			pageCategoryLevel1: sectionLevel, // Page category (level 1) of the content or template name
			contentID: contentID,
		});
		// Google Tag Manager DataLayer : VISITOR
		dataLayer.push({
			visitorLoginState: loginStatus, // Login state of the user, anonymous for public websites
			anonymousTracking: getConsentStatus(false), // Consent for anonymous tracking
			loggedTracking: getConsentStatus(true), // Consent for logged tracking with userID
			// visitorId: getVisitorID() // Visitor ID
		});

		dataLayer.push({
			event: 'VirtualPageview',
			virtualPageURL: props.location.pathname,
			virtualPageTitle: getPageName(props),
		});

		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', getEnvironment() === 'stg' ? 'GTM-TZPGC2D' : 'GTM-5NQNMZ8');
	}
};
