import styled from 'styled-components';
import {ButtonStyles} from '../../Elements/Button/styles';
import {HeadlineStyledH2} from '../../Elements/Headline/styles';
import {withUnit, media} from '../../../styles/utils';
import {sizes, colors} from '../../../styles/variables';
import {Icon} from '../../Elements/Icon/Icon';
import Button from '../../Elements/Button/Button';

export const HeadlineStyled = styled(HeadlineStyledH2)``;

export const TextStyled = styled.div`
	${media.sm`
		padding-right: 15%;
	`};
`;

export const ButtonContainerStyled = styled.div`
	margin-top: ${withUnit(sizes.distance.base)};
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;

	${media.sm`
		justify-content: flex-start;
	`}

	${media.lg`
		margin-top: ${withUnit(sizes.distance.base * 1.5)};
	`};
`;

export const ButtonStyled = styled(Button)`
	${ButtonStyles};
	margin: 0 15px 0 0;
`;

export const ButtonLinkStyled = styled.a`
	${ButtonStyles};
	margin: 0;
`;

export const IconContainerStyled = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const IconStyled = styled(Icon)`
	display: none;
	width: 40%;
	height: auto;
	position: absolute;
	z-index: -1;
	bottom: 0;
	right: 0;
	transform: rotate(8deg);

	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	@media (min-height: 720px) {
		display: block;
	}

	svg {
		fill: ${colors.snow};
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		${media.lg`
			fill: ${colors.lightgrey};
		`};
	}
`;
