import styled, {css} from 'styled-components';
import {gradientButton} from '../../../styles/utils';
import Link from '../Link/Link';
import {colors, fonts, sizes} from '../../../styles/variables';

export const themes = ['white', 'snow', 'candy', 'colored', 'coloredRTL', 'purple'];

export const themeColors = {
	white: {
		background: colors.white,
		hover: colors.almostwhite,
		text: colors.black,
	},
	snow: {
		background: colors.snow,
		hover: colors.white,
		text: colors.black,
	},
	grey: {
		background: colors.lightgrey,
		hover: colors.snow,
		text: colors.black,
	},
	coloredRTL: {
		background: colors.candy,
		hover: colors.candy,
		text: colors.white,
	},
	purple: {
		background: colors.purple,
		hover: colors.purple,
		text: colors.white,
	},
};

const ButtonDefaultStyles = css`
	${gradientButton()};
	color: ${colors.white};
	font-family: ${fonts.sans_serif};

	&:hover,
	&:active,
	&:focus {
		color: ${colors.white};
	}
`;

const ButtonThemeStyles = css`
	background-color: ${(props) => props.colors.background};
	color: ${(props) => props.colors.text};

	&:hover,
	&:active,
	&:focus {
		background-color: ${(props) => props.colors.hover};
		color: ${(props) => props.colors.text};
	}
`;

export const ButtonStyles = css`
	float: ${(props) => (props.direction === 'coloredRTL' ? 'right' : '')};

	display: inline-flex;
	align-items: center;
	overflow: hidden;
	box-sizing: border-box;
	font-family: ${fonts.sans_serif};

	height: ${sizes.forms.buttonHeight};
	border-radius: ${sizes.borderRadius};
	border: 0;
	padding: 0 2em;
	z-index: 1;
	pointer-events: all;
	font-size: ${(props) => (props.size === 'small' ? `0.8em` : `1em`)};
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	margin: 1em;

	will-change: background-color, background-position, transform, font-size, box-shadow;
	transition: all 0.3s ease-out;

	${(props) => (props.theme !== 'candy' && props.theme !== 'colored' ? ButtonThemeStyles : ButtonDefaultStyles)};

	&:not([disabled]):hover {
		transform: translateY(-3px);
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	}

	&:focus,
	&:hover {
		cursor: pointer;
		outline: 0;
	}

	&[disabled] {
		opacity: 0.5;
		pointer-events: none;
		cursor: initial;
		transition: opacity 0.15s;
	}

	span {
		pointer-events: none;
		width: 100%;
	}
`;

export const ButtonStyled = styled.button`
	${ButtonStyles};
`;

export const ButtonLinkStyled = styled(Link)`
	${ButtonStyles};
`;
