import React from 'react';
import PropTypes from 'prop-types';
import {TextStyled} from './styles';

const Text = (props) => {
	const {className, maxWidth, content, center, styles, direction = 'ltr'} = props;

	const beforeParse = content;

	let afterParse = null;

	const regex1 = /&lt;&lt;/gi;
	const regex2 = /&gt;&gt;/gi;

	afterParse = beforeParse.replace(regex1, '</p><p dir="rtl" style="text-align: right;">');
	afterParse = afterParse.replace(regex2, '</p><p>');

	const textProps = {
		maxWidth,
		styles,
	};
	return (
		<TextStyled
			center={center}
			className={className}
			align={direction}
			{...textProps}
			dangerouslySetInnerHTML={{__html: afterParse}}
		/>
	);
};

Text.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	content: PropTypes.string.isRequired,
	maxWidth: PropTypes.number,
	center: PropTypes.bool,
	styles: PropTypes.object,
};

Text.defaultProps = {
	className: 'ap-text',
	maxWidth: null,
	center: false,
	styles: null,
};

/** @component */
export default Text;
