import React from 'react';
import PropTypes from 'prop-types';
import {ButtonStyled, ButtonLinkStyled, themes, themeColors} from './styles';

const Button = ({className, onClick, size, text, theme, to, state, type, children, direction, disabled}) => {
	const colors = theme === 'candy' ? null : themeColors[theme];

	// see also buttonPropsForLink below
	const buttonProps = {
		className: className || 'ap-button',
		colors,
		onClick,
		size,
		disabled,
		direction,
		children,
		theme: theme || 'candy',
	};

	switch (type) {
		case 'link': {
			const buttonPropsForLink = {to, state};
			return (
				<ButtonLinkStyled {...buttonProps} {...buttonPropsForLink}>
					<span dangerouslySetInnerHTML={{__html: text}} />
					{children}
				</ButtonLinkStyled>
			);
		}
		case 'submit':
			// almost identical to "default:, add prop type="submit""
			return (
				<ButtonStyled type="submit" {...buttonProps}>
					<span dangerouslySetInnerHTML={{__html: text}} />
					{children}
				</ButtonStyled>
			);
		case 'button':
			// almost identical to "default:, add prop type="submit""
			return (
				<ButtonStyled type="button" {...buttonProps}>
					<span dangerouslySetInnerHTML={{__html: text}} />
					{children}
				</ButtonStyled>
			);
		default:
			return (
				<ButtonStyled {...buttonProps}>
					<span dangerouslySetInnerHTML={{__html: text}} />
					{children}
				</ButtonStyled>
			);
	}
};

Button.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.string,
	text: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(themes),
	to: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.symbol]),
};

Button.defaultProps = {
	className: null,
	onClick: null,
	size: 'default',
	theme: 'candy',
	to: null,
	type: 'button',
	children: null,
};

/** @component */
export default Button;
