import React from 'react';
import {navigate} from 'gatsby';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {ButtonStyled, BannerStyled, BannerContent, Blurb} from './styles';
import {clearErrors} from '../../../state/actions/userManagement';

const Banner = (props) => {
	const {content, showButton, type, targetBtnText, isUpstairs, clearAllErrors} = props;

	function handleClick() {
		let target = null;
		if (content.target !== null || content.target !== undefined) {
			target = {content};
		}

		if (target !== undefined && target !== null) {
			navigate(target.link, {
				state: {
					...target.state,
				},
			});
		}
		clearAllErrors();
	}

	let btnText = 'OK';

	if (content && content.button) {
		btnText = content.button;
	}

	return !_.isEmpty(content) ? (
		<BannerStyled isUpstairs={isUpstairs} type={type}>
			<BannerContent>
				<Blurb type={type}>{content.text}</Blurb>
				{showButton && targetBtnText && <ButtonStyled text={btnText} theme="white" onClick={handleClick} />}
			</BannerContent>
		</BannerStyled>
	) : null;
};

Banner.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	showButton: PropTypes.bool,
	type: PropTypes.oneOf(['error', 'info']),
	target: PropTypes.string,
	targetBtnText: PropTypes.string,
	clearAllErrors: PropTypes.func,
	isUpstairs: PropTypes.bool,
};

Banner.defaultProps = {
	content: null,
	showButton: true,
	type: 'info',
	target: null,
	targetBtnText: null,
	clearAllErrors: null,
	isUpstairs: false,
};

const mapStateToProps = (state) => ({
	content: state.messages.error || state.messages.info,
	type: state.messages.error ? 'error' : 'info',
	showButton: state.messages.showButton,
});

export default connect(mapStateToProps, {clearAllErrors: clearErrors})(Banner);
