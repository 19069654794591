const formselectdata = require('../utils/formselectdata');
/**
 * Configuration.
 */
module.exports = {
	seo: {
		titleSuffix: '– mein mslife',
		title: 'mein mslife',
		description: 'Individuelle Begleitung für ein selbstbestimmtes Leben mit MS',
		opengraphSitename: 'mein mslife',
		opengraphTitle: 'Multiple Sklerose Portal',
		opengraphDescription: 'Individuelle Begleitung für ein selbstbestimmtes Leben mit MS'
	},
	hostSiblings: ['biogen', 'ms-life.de', 'mailto', 'cleo-app.de'],
	formselectdata,
	login: {
		special:
			'dimethylfumarat,interferon,natalizumab,peginterferon,peginterferon-beta-1a-s-c,interferon-beta-1a-i-m,fampridin,diroximelfumarat',
		specialProducts: 'avonex,plegridy,tecfidera,fampyra,tysabri,vumerity',
		secureTemplates: 'wirkstoff'
	}
};
