import {HIDE_DISCLAIMER_EXTERNAL_LINK, SHOW_DISCLAIMER_EXTERNAL_LINK} from '../constants';

export const showDisclaimerExternalLink = to => ({
	type: SHOW_DISCLAIMER_EXTERNAL_LINK,
	payload: {
		to
	}
});

export const hideDisclaimerExternalLink = () => ({
	type: HIDE_DISCLAIMER_EXTERNAL_LINK
});
