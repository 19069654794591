import styled from 'styled-components';
import {colors, fonts} from '../../../styles/variables';
import {media} from '../../../styles/utils';
import {GlobalListStyle, GlobalOrderedListStyle} from '../../../styles/global';

export const TextStyled = styled.div`
	color: ${colors.black};
	font-family: ${fonts.sans_serif};
	font-size: 1em;
	${(props) => (props.align === 'rtl' ? `text-align: right; direction: rtl` : `text-align: left; direction: ltr`)};
	${(props) => (props.center ? `text-align: center;` : ``)};
	
	${(props) =>
		props.maxWidth
			? `
		max-width: ${props.maxWidth}px;
		margin: 0 auto;
	`
			: ``};

	p {
		margin-top: 0;
		margin-bottom: 1.2em;

		${media.lg`
			margin-bottom: 1.5em;
		`};
	}

	a {
		&, 
		&:visited {
			color: ${colors.carrot};
		    font-weight: 600;
		}
		
		&:hover, 
		&:active, 
		&:focus {
			color ${colors.candy};
		    font-weight: 600;
		}
	}
	
	
		${GlobalListStyle};
	
	ol {
		li::before {
			height: 0;
		}
	}

	ul {
		li::before {
			height: 12px;
		}
	}

	${(props) => props.styles};
`;
