import styled from 'styled-components';
import {withUnit, media} from '../../../styles/utils';
import {colors, sizes} from '../../../styles/variables';
import Hotline from '../../Content/Hotline/Hotline';

export const FooterStyled = styled.div`
	box-sizing: border-box;
	padding: ${withUnit(sizes.distance.base / 2)} ${withUnit(sizes.distance.base * 0.75)};
	background-color: ${colors.white};
	align-items: center;
	font-size: 1.1em;
	border-top: 2px solid ${colors.snow};

	${media.xl` 
		padding: ${withUnit(sizes.distance.base / 2)} ${withUnit(sizes.distance.base * 2)};
	`};
`;

export const FooterNavigationStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.xxl`
		flex-direction: row;
	`}
`;

export const HotlineStyled = styled(Hotline)``;

export const FooterRow = styled.div`
	display: flex;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	${props => (props.align === 'right' ? 'justify-content: center;' : '')};

	${media.xxl`
		flex-direction: row;
	`}
`;
