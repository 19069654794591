import styled from 'styled-components';
import {colors, fonts, sizes} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';

export const themes = {
	default: {
		bg: 'transparent',
	},
	white: {
		bg: colors.white,
	},
	grey: {
		bg: colors.snow,
	},
};

export const SectionStyled = styled.div`
	background-color: ${(props) => props.theme.bg};
	font-family: ${fonts.sans_serif};
	color: ${colors.black};
	${(props) => (props.center ? `text-align: center;` : ``)};

	${(props) =>
		props.padding ? `padding: ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base / 2)};` : ``};

	${(props) => (props.noBtmPad ? `padding-bottom: 0;` : ``)};

	${(props) => (props.paddingBottom ? `padding-bottom: 4em;` : ``)};
	${(props) => (props.paddingTop ? `padding-top: 4em;` : ``)};

	${media.sm`
		${(props) => (props.padding ? `padding: ${withUnit(sizes.distance.base * 2)} ${withUnit(sizes.distance.base)};` : ``)};
		${(props) => (props.noBtmPad ? `padding-bottom: 0;` : ``)};
	`}

	${media.lg`
		${(props) =>
			props.padding ? `padding: ${withUnit(sizes.distance.base * 3)} ${withUnit(sizes.distance.base * 2)};` : ``};
			${(props) => (props.noBtmPad ? `padding-bottom: 0;` : ``)};
	`}
`;
