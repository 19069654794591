import React from 'react';
import PropTypes from 'prop-types';
import {BurgerStyled, BurgerBoxStyled, BurgerInnerStyled} from './styles';

const Burger = props => {
	const {onClick} = props;
	return (
		<BurgerStyled className="ap-burger" {...props} onClick={onClick}>
			<BurgerBoxStyled {...props}>
				<BurgerInnerStyled {...props} />
			</BurgerBoxStyled>
		</BurgerStyled>
	);
};

Burger.propTypes = {
	isOpen: PropTypes.bool,
	/**  Gets called when the user clicks on the burger. */
	onClick: PropTypes.func
};

Burger.defaultProps = {
	isOpen: false,
	onClick: null
};

/** @component */
export default Burger;
