import styled, {css} from 'styled-components';
import {gradientCandyCarrot, media} from '../../../styles/utils';
import {colors, fonts, sizes} from '../../../styles/variables';
import Text from '../Text/Text';

export const IconButtonStyles = css`
	position: relative;
	//display: block;
	// make this makes sense:
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
	height: ${sizes.forms.buttonHeight};

	border: 0;
	border-radius: ${sizes.borderRadius};
	margin: auto;
	padding: 0;
	z-index: 1;
	pointer-events: all;
	flex-shrink: 0;

	font-family: ${fonts.sans_serif};
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	font-size: 1em;
	transition: background 0.3s ease, font-size 0.3s ease;

	// todo: fix ie later on but this is causing even more problems
	//svg {
	//	height: 100%;
	//}

	${props =>
		props.theme === 'white'
			? `
				background-color: ${colors.white};
				color: ${colors.black};

				svg {
					color: ${colors.carrot};
				}
				`
			: `
				${gradientCandyCarrot()}
				color: ${colors.white};

				svg {
					color: ${colors.white};
				}
	`};

	.ap-icon {
		font-size: ${props => (props.size === 'small' ? `0.7em` : `0.8em`)};
		// todo: find another fix for ipad icon buttons, might call for a flexbox fix
		//width: 100%;
		//height: 100%;
	}

	& + & {
		margin-top: 1em;

		${media.sm`
			margin-left: 1em;
			margin-top: 0;
  		`}
	}

	&:not([disabled]):hover {
	}

	&:focus,
	&:hover {
		cursor: pointer;
		outline: 0;
	}

	&[disabled] {
		opacity: 0.5;
		pointer-events: none;
		cursor: initial;
		transition: opacity 0.15s;
	}

	> * {
		pointer-events: none;
	}
`;

// This one needs to be a button element, as it is used in forms for submit.
export const IconButtonStyled = styled.button`
	${IconButtonStyles};
`;

export const IconButtonContainerStyled = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	color: ${colors.black};

	.ap-iconbutton {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		width: ${sizes.forms.smallButtonHeight};
		height: ${sizes.forms.smallButtonHeight};
		margin: 0;
		font-size: 0.8em;

		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
		transition: transform 0.3s ease, box-shadow 0.3s ease;

		.ap-icon {
			font-size: 1rem;
		}
	}

	&:hover .ap-iconbutton {
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
		transform: translateY(-2px);
	}
`;

export const TextStyled = styled(Text)`
	margin: 0 0 0 0.5em;
	font-weight: 600;
`;
