import React from 'react';
import Logo from '../../../assets/img/logos/logo_biogen.svg';
import {LogoBiogenStyled, LinkStyled} from './styles';

const LogoBiogen = props => {
	return (
		<LinkStyled to="https://www.biogen.de/" className="ap-logo-mslife">
			<LogoBiogenStyled src={Logo} alt="Biogen" {...props} />
		</LinkStyled>
	);
};

/** @component */
export default LogoBiogen;
