import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import MenuMainContent from './MenuMainContent';

const MenuMain = ({upstairs, activeSlug}) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main-menu"}}) {
					edges {
						node {
							name
							slug
							items {
								title
								object_slug
								path
								url
								type
							}
						}
					}
				}
			}
		`}
		render={(data) => <MenuMainContent data={data} upstairs={!!upstairs} activeSlug={activeSlug} />}
	/>
);

export default MenuMain;

MenuMain.propTypes = {
	upstairs: PropTypes.bool.isRequired,
	activeSlug: PropTypes.string.isRequired,
};
