/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import {themes, SectionStyled} from './styles';

const Section = (props) => {
	const {theme, showPadding, center, children, className, pageParent, noBtmPad, paddingBottom, paddingTop} = props;
	let solrParentTitle = '';
	// let category = 'multiple-sklerose';
	// const breadcrumb = 'multiple-sklerose'; # Never used
	const sectionProps = {
		theme: themes[theme],
		padding: showPadding,
		center,
		noBtmPad,
		paddingBottom,
		paddingTop,
	};
	if (pageParent && pageParent.slug) {
		solrParentTitle = `pageCategory_${pageParent.slug}`;
		// const category = pageParent.slug; #Never used
	}

	return (
		<SectionStyled className={`${className} ${solrParentTitle}`} {...sectionProps}>
			{children}
		</SectionStyled>
	);
};

Section.propTypes = {
	theme: PropTypes.string,
	className: PropTypes.string,
	showPadding: PropTypes.bool,
	paddingBottom: PropTypes.bool,
	paddingTop: PropTypes.bool,
	center: PropTypes.bool,
	noBtmPad: PropTypes.bool,
	pageParent: PropTypes.shape({slug: PropTypes.string}),
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Section.defaultProps = {
	theme: 'default',
	className: '',
	showPadding: false,
	center: false,
	paddingBottom: false,
	paddingTop: false,
	children: null,
	pageParent: null,
	noBtmPad: false,
};

/** @component */
export default Section;
