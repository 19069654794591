import React, {useState, useEffect, useLayoutEffect} from 'react';
import {isAndroid, isIOS, isMobileSafari} from 'react-device-detect';
import {AnimatePresence, AnimateSharedLayout} from 'framer-motion';

import PhoneNumber from '../Hotline/PhoneNumber';
import {Icon} from '../../Elements/Icon/Icon';
import Button from '../../Elements/Button/Button';
import Link from '../../Elements/Link/Link';

import IcoChat from '../../../assets/img/icons/service-menu/img-btn-chat.svg';
// import IcoInternational from '../../../assets/img/icons/service-menu/img-btn-international.svg';
import IcoTogether from '../../../assets/img/icons/service-menu/img-btn-together.svg';
import IcoMail from '../../../assets/img/icons/service-menu/img-btn-mail.svg';
import IcoPhone from '../../../assets/img/icons/service-menu/img-btn-phone.svg';
import IcoPlus from '../../../assets/img/icons/service-menu/img-btn-plus.svg';
import IcoBtn from '../../../assets/img/icons/service-menu/img-btn-arrow.svg';

import {ServiceTabsStyled, TabsStyled, TabStyled, PanelStyled, CloseStyled, ImageStyled, BtnUpStyled} from './styles';

const ServiceTabs = () => {
	const [active, setActive] = useState(false);
	const [big, setBig] = useState(false);
	const [showUp, setShowUp] = useState(false);
	const [browserType, setBrowserType] = useState(
		'https://cleo-app-de.onelink.me/Ae8g?pid=download-link&c=Cleo-DE_MS-life_Kontaktlasche&af_channel=MS-life.de&utm_medium=MS-life.de&utm_source=download-link&utm_campaign=Cleo-DE_MS-life_Kontaktlasche&af_dp=cleo%3A%2F%2Fauth%2Fchat%3Futm_medium%3DMS-life.de%26utm_source%3Ddownload-link%26utm_campaign%3DCleo-DE_MS-life_Kontaktlasche&af_web_dp=https%3A%2F%2Fwww.cleo-app.de'
	);

	const getBrowser = () => {
		if (isIOS && isMobileSafari)
			setBrowserType(
				'https://cleo-app-de.onelink.me/Ae8g?pid=download-link&c=Cleo_DE_Microsite_Download-Link&af_channel=microsite&af_keyword=app-store&utm_medium=microsite&utm_source=download-link&utm_campaign=Cleo_DE_Microsite_Download-Link&utm_term=app-store&af_dp=cleo%3A%2F%2Fauth%2Fhome%3Futm_medium%3Dmicrosite%26utm_source%3Ddownload-link%26utm_campaign%3DCleo_DE_Microsite_Download-Link%26utm_term%3Dapp-store&af_web_dp=https%3A%2F%2Fitunes.apple.com%2Fde%2Fapp%2Fcleo-meine-ms-app%2Fid1372835752%3Fls%3D1'
			);

		if (isAndroid)
			setBrowserType(
				'https://cleo-app-de.onelink.me/Ae8g?pid=download-link&c=Cleo_DE_Microsite_Download-Link&af_channel=microsite&af_keyword=play-store&utm_medium=microsite&utm_source=download-link&utm_campaign=Cleo_DE_Microsite_Download-Link&utm_term=play-store&af_dp=cleo%3A%2F%2Fauth%2Fhome%3Futm_medium%3Dmicrosite%26utm_source%3Ddownload-link%26utm_campaign%3DCleo_DE_Microsite_Download-Link%26utm_term%3Dplay-store&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.biogen.cleo.de%26hl%3Dde'
			);
	};

	const handleScroll = () => {
		window.pageYOffset >= window.innerHeight / 2 ? setShowUp(true) : setShowUp(false);
	};

	useEffect(() => {
		getBrowser();
	});

	useLayoutEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const container = {
		hidden: {
			opacity: 0,
			transitionEnd: {
				display: 'none',
			},
		},
		show: {
			opacity: 1,
			display: 'flex',
			transition: {
				staggerChildren: 0.1,
				staggerDirection: -1,
			},
		},
		leave: {
			opacity: 0,
			transitionEnd: {
				display: 'none',
			},
		},
	};

	const item = {
		hidden: {opacity: 1, rotate: 0},
		show: {opacity: 1, rotate: 45},
	};

	const item2 = {
		hidden: {opacity: 0, y: -50, x: 0},
		show: {opacity: 1, y: -110, x: 0},
	};

	const item3 = {
		hidden: {opacity: 0, y: -30, x: 65},
		show: {opacity: 1, y: -52, x: 17},
	};

	const item4 = {
		hidden: {opacity: 0, y: -45, x: 110},
		show: {opacity: 1, y: -12, x: 62},
	};

	const item5 = {
		hidden: {opacity: 0, y: -45, x: 180},
		show: {opacity: 1, y: 19, x: 180},
	};

	const btnUp = {
		hidden: {opacity: 0, y: 40, x: 0},
		show: {
			transition: 1,
			opacity: 1,
			y: 0,
			x: 0,
		},
	};

	return (
		<>
			<ServiceTabsStyled>
				<AnimateSharedLayout type="crossfade" exitBeforeEnter>
					<TabsStyled variants={container} initial="hidden" animate={big ? 'show' : 'hidden'}>
						<AnimatePresence>
							<TabStyled key="3" className="cleo" inactive={active} variants={item5}>
								<Link to={`${browserType}`}>
									<ImageStyled src={IcoChat} />
								</Link>
							</TabStyled>

							<TabStyled key="2" className="mail" inactive={active} variants={item4}>
								<Link to="mailto:info@ms-service-center.de">
									<ImageStyled src={IcoMail} />
								</Link>
							</TabStyled>

							<TabStyled key="1" className="service" onClick={() => setActive(!active)} variants={item3}>
								<AnimatePresence>
									{!active && (
										<PanelStyled active={!active} layoutId="test" className="tiny">
											<ImageStyled src={IcoPhone} />
										</PanelStyled>
									)}
									{active && (
										<PanelStyled active={active} layoutId="test" className="big">
											<strong>Wir sind für dich da: </strong>
											<PhoneNumber />
											<Button
												theme="white"
												to="/service/service-team"
												type="link"
												text="Zum MS Service-Center"
											/>
											<CloseStyled onClick={() => setActive(!active)}>
												<Icon name="close_arrow" type="tabs" />
											</CloseStyled>
										</PanelStyled>
									)}
								</AnimatePresence>
							</TabStyled>
							{/* <TabStyled key="4" className="international" inactive={active} variants={item2}>
								<Link to="/service/international/">
									<ImageStyled src={IcoInternational} />
								</Link>
							</TabStyled> */}
							<TabStyled key="4" className="international" inactive={active} variants={item2}>
								<Link to="/nutritionmanager/">
									<ImageStyled src={IcoTogether} />
								</Link>
							</TabStyled>
						</AnimatePresence>
					</TabsStyled>

					<TabsStyled>
						<TabStyled
							key="0"
							className="close-2"
							initial="hidden"
							animate={big ? 'show' : 'hidden'}
							onClick={() => setBig(!big)}
							variants={item}
							whileHover={{y: -2}}>
							<ImageStyled src={IcoPlus} />
						</TabStyled>
						<AnimatePresence>
							{showUp && !big && (
								<TabStyled
									key="10"
									className="btn-up"
									inactive={active}
									onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
									variants={btnUp}
									whileHover={{y: -2}}
									whileTap={{y: 3}}
									initial="hidden"
									animate={showUp ? 'show' : 'hidden'}
									exit="hidden">
									<BtnUpStyled>
										<ImageStyled src={IcoBtn} />
									</BtnUpStyled>
								</TabStyled>
							)}
						</AnimatePresence>
					</TabsStyled>
				</AnimateSharedLayout>
			</ServiceTabsStyled>
		</>
	);
};

/** @component */
export default ServiceTabs;
