import React from 'react';
import PropTypes from 'prop-types';
import {IconButtonContainerStyled, IconButtonStyled, TextStyled} from './styles';

const IconButton = props => {
	const {children, theme, onClick, className, text} = props;
	const buttonProps = {
		theme
	};

	if (text) {
		return (
			<IconButtonContainerStyled className="ap-iconbutton-container">
				<IconButtonStyled className={className} {...buttonProps} onClick={onClick}>
					{children}
				</IconButtonStyled>
				<TextStyled content={text} />
			</IconButtonContainerStyled>
		);
	}

	return (
		<IconButtonStyled className={className} {...buttonProps} onClick={onClick}>
			{children}
		</IconButtonStyled>
	);
};

IconButton.propTypes = {
	theme: PropTypes.oneOf(['white', 'candy']),
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	className: PropTypes.string,
	text: PropTypes.string
};

IconButton.defaultProps = {
	theme: 'candy',
	onClick: null,
	children: null,
	className: 'ap-iconbutton',
	text: ''
};

/** @component */
export default IconButton;
