import {useEffect} from 'react';

const GtmEventListener = () => {
	const handleGtmClick = (eventAsset, eventAssetBis) => {
		dataLayer.push({
			event: 'biib_internalLinkClick',
			eventAsset,
			eventAssetBis,
		});
	};

	useEffect(() => {
		const telElement = document.getElementsByClassName('gtm-service-center-tel');
		for (let i = 0; i < telElement.length; i += 1) {
			telElement[i].addEventListener('click', () =>
				handleGtmClick('click on the MS Service Center telephone number', '0800 030 77 30')
			);
		}
		const serviceTelElement = document.getElementsByClassName('gtm-service-team-tel');
		for (let i = 0; i < serviceTelElement.length; i += 1) {
			serviceTelElement[i].addEventListener('click', () =>
				handleGtmClick('click on the Service Team telephone number', '0800 030 77 30')
			);
		}
		const serviceMailElement = document.getElementsByClassName('gtm-service-mail');
		for (let i = 0; i < serviceMailElement.length; i += 1) {
			serviceMailElement[i].addEventListener('click', () =>
				handleGtmClick('click on the Service Team email address', 'info@ms-service-center.de')
			);
		}
	}, []);
	return null;
};

export default GtmEventListener;
