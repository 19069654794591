import styled from 'styled-components';
import {media, withUnit, gradientCandyCarrot} from '../../../styles/utils';
import {colors, sizes, zindex} from '../../../styles/variables';
import Button from '../../Elements/Button/Button';
import {Icon} from '../../Elements/Icon/Icon';

export const NavbarStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: ${(props) =>
		props.upstairs ? `${withUnit(sizes.navbar.sm.default)}` : `${withUnit(sizes.navbar.sm.upstairs)}`};
	transition: height 0.25s;
	background-color: ${colors.white};
	box-sizing: border-box;
	z-index: ${zindex.navbar};
	padding: 0 0 0 ${withUnit(sizes.distance.base / 2)};
	font-size: 1.1em;

	${(props) =>
		props.upstairs ? `border-bottom: 2px solid ${colors.snow}` : `box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);`};

	${media.sm`
		padding: 0 0 0 ${withUnit(sizes.distance.base)};
	`}

	${media.lg`
		height: ${(props) => (props.upstairs ? `${withUnit(sizes.navbar.default)}` : `${withUnit(sizes.navbar.upstairs)}`)};
		padding: 0 0 0 ${withUnit(sizes.distance.base * 2)};
	`}
`;

export const IconStyled = styled(Icon)`
	cursor: pointer;
	pointer-events: all;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 ${withUnit(sizes.distance.base)};
	border-left: 2px solid ${colors.snow};
	pointer-events: all;

	svg {
		fill: ${colors.carrot};
	}
`;

export const ButtonStyled = styled(Button)`
	margin: 0 1em 0 0;
`;

export const NavbarNavigation = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const PageProgressWrapper = styled.div`
	background: rgba(255, 255, 255, 0.14);
	height: 15px;
	position: fixed;
	top: ${(props) =>
		props.upstairs ? `${withUnit(sizes.navbar.sm.default)}` : `${withUnit(sizes.navbar.sm.upstairs)}`};
	left: 0;
	z-index: 101;
	width: 100%;
`;

export const PageProgress = styled.div.attrs((props) => ({
	style: {
		width: props.width,
	},
}))`
	height: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	${media.lg`
		height: 15px;
	`}
	${gradientCandyCarrot()};
`;
