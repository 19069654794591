import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';

const burger = {
	layerWidth: 24,
	layerHeight: 2,
	layerSpacing: 7
};

// State: open
// language=SCSS
export const openStyles = `
    & {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    
        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }
    
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
`;

export const BurgerStyled = styled.div`
	display: inline-flex;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	transition-property: opacity, top, left;
	transition-duration: 0.5s, 0.2s, 0.2s;
	transition-timing-function: ease-in;

	color: ${colors.white};
	font: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	z-index: 1001;
	box-sizing: border-box;

	height: 100%;
	align-items: center;
	padding: 0 ${withUnit(sizes.distance.base)};
	border-left: 2px solid ${colors.snow};

	${media.print`
		display: none;
	`};
`;

export const BurgerBoxStyled = styled.div`
	pointer-events: none;
	width: ${burger.layerWidth}px;
	height: ${burger.layerHeight * 3 + burger.layerSpacing * 2}px;
	display: inline-block;
	position: relative;
`;

export const BurgerInnerStyled = styled.div`
	// Default
	display: block;
	top: 50%;
	margin-top: ${burger.layerHeight / -2}px;

	&,
	&::before,
	&::after {
		position: absolute;
		width: ${burger.layerWidth}px;
		height: ${burger.layerHeight}px;
		background-color: ${colors.carrot};
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: '';
		display: block;
	}

	// Effect
	transition-duration: 0.075s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

	&::before {
		top: ${(burger.layerSpacing + burger.layerHeight) * -1}px;
		transition: top 0.075s 0.12s ease, opacity 0.075s ease;
	}

	&::after {
		bottom: ${(burger.layerSpacing + burger.layerHeight) * -1}px;
		transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	${props => (props.isOpen ? `${openStyles}` : '')};
`;
