import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../assets/img/logos/logo_ms-life.svg';
import {MSlifeLogoStyled, LinkStyled} from './styles';

const LogoMSlife = props => {
	const {upstairs} = props;
	return (
		<LinkStyled to="/" className="ap-logo-mslife">
			<MSlifeLogoStyled src={Logo} upstairs={upstairs} />
		</LinkStyled>
	);
};

/** @component */
export default LogoMSlife;

LogoMSlife.propTypes = {
	upstairs: PropTypes.bool.isRequired
};
