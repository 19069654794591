import styled from 'styled-components';
import {colors, sizes, zindex} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';
import {Icon} from '../../Elements/Icon/Icon';
import Link from '../../Elements/Link/Link';
import {HeadlineStyledH4} from '../../Elements/Headline/styles';

/**
 * CUSTOM BURGER MENU STYLING ON EXTERNAL COMPONENT
 */
export const StyledBurgerMenu = styled.div`
	.bm-menu-wrap {
		top: 0;
		width: 95% !important;
		box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.15);
		height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;

		${media.md`
			width: 50% !important;
			min-width: 420px;
  		`};

		${media.xl`
			width: 25% !important;
  		`};
	}

	.bm-item {
	}

	.bm-item:focus {
		outline: none;
	}

	.bm-cross-button {
		width: 28px !important;
		height: 29px !important;
		right: ${withUnit(sizes.distance.base / 2)} !important;
		top: ${withUnit(sizes.distance.base / 2)} !important;
		z-index: ${zindex.burgerMenuCross};

		${media.md`
			right: ${withUnit(sizes.distance.base)} !important;
			top: ${withUnit(sizes.distance.base)} !important;
  		`};

		> span {
			top: 0 !important;
			right: 16px !important;
		}

		button:focus {
			outline: 0;
		}
	}

	.bm-cross {
		//display: none;
		background: ${colors.carrot};
		height: 30px !important;
		width: 2px !important;
		transition: background 0.3s ease;
	}

	.bm-cross-button:hover .bm-cross {
		background: ${colors.candy};
	}

	.bm-menu {
		background: white;
	}

	.bm-item-list {
		color: ${colors.black};
	}

	.bm-overlay {
		top: 0;
		z-index: 1050 !important;
		background: ${colors.carrot80}!important;
	}
`;

export const HeadlineStyled = styled(HeadlineStyledH4)`
	font-weight: 800;
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${colors.black};
	font-weight: 300;
	transition: color 0.3s ease;
	${props => (props.active ? `font-weight: 600;` : ``)};

	&:hover,
	&:active,
	&:focus {
		color: ${colors.carrot};
	}
`;

export const IconStyled = styled(Icon).attrs(props => ({
	height: props.height || '1em',
	width: props.width || '1em'
}))`
	cursor: pointer;
	pointer-events: auto;
	line-height: 1em;

	svg {
		pointer-events: none;
		fill: ${colors.carrot};
	}

	&:hover,
	&:focus {
		svg {
			fill: ${colors.candy};
		}
	}
`;

export const BackLink = styled.div`
	margin-bottom: ${withUnit(sizes.distance.base)};
	cursor: pointer;

	> ${IconStyled} {
		pointer-events: none;
		margin-right: 1em;
	}
`;

/**
 * MENU ITEMS
 */
export const FooterMenuItemsContainer = styled.div`
	margin-bottom: ${withUnit(sizes.distance.base)};

	${media.md`
		margin-bottom: 0;
	`};
`;

export const FooterMenuItemContainer = styled.div`
	padding: 1vh 0;
	font-size: 0.8em;
`;

export const MenuItemsContainer = styled.div`
	flex: 1 0 auto;
	padding: ${withUnit(sizes.distance.base)} 0 0 0;
	width: 100%;

	${media.md`
		padding: ${withUnit(sizes.distance.base * 2)} 0;
	`};
`;

export const MenuItemContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2vh 0;
`;

export const SubMenuItemContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1vh 0;
`;

/**
 * LAYER
 */
export const Layer = styled.div`
	position: absolute;
	top: 0;
	box-sizing: border-box;
	width: 100%;
	min-height: 100vh;
	overflow: auto;
	transition: all 500ms ease !important;
	padding: ${withUnit(sizes.distance.base * 0.75)};
	padding-top: ${withUnit(sizes.distance.base * 2)};
	background: white;

	display: flex !important;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	${media.xs`
		padding: ${withUnit(sizes.distance.base * 2)};
		padding-top: ${withUnit(sizes.distance.base * 3)};
	`};
`;

export const LayerOne = styled(Layer)`
	justify-content: space-between;
`;

export const LayerTwo = styled(Layer)`
	width: calc(100%);
	transform: translateX(${props => (props.active ? '0' : '135%')});
	z-index: 100;

	${media.md`
		width: calc(100% - 1.5em);
		transform: translateX(${props => (props.active ? '1.5em' : '135%')});
	`}
`;

export const LayerThree = styled(Layer)`
	width: calc(100%);
	transform: translateX(${props => (props.active ? '0' : '135%')});
	z-index: 200;
	${media.md`
		width: calc(100% - 3em);
		transform: translateX(${props => (props.active ? '3em' : '135%')});
	`};
`;
