import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';

import {GlobalStyle} from '../../styles/global';
import {initTracking} from '../../utils/trackingUtils';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import BurgerMenuWrapper from './BurgerMenu/BurgerMenuWrapper';
import WindowScrollListener from '../Container/Scrolling/WindowScrollListener';
import DisclaimerExternalLink from './DisclaimerExternalLink/Container';
import ObserveExternalLinks from '../FunctionalElements/ObserveExternalLinks';
import GtmEventListener from '../FunctionalElements/GtmEventListener';
import Banner from './Banner/Banner';
import ServiceTabs from '../Content/ServiceTabs/ServiceTabs';
import {privateUrl} from '../../utils/private-url';

/**
 * Layout component.
 */
class Layout extends React.PureComponent {
	constructor(props) {
		super(props);
		this.mount = true;
		this.state = {
			menuIsOpen: false,
			render: null,
		};
	}

	componentDidMount = () => {
		// initialize basic tracking - storing of basic site informations
		initTracking(this.props);
		this.setState({render: 'RENDER'});
	};

	componentWillUnmount = () => {
		this.mount = false;
	};

	// methods bound to 'this'
	handleBurgerItemClick = () => {
		// toggle this.state.menuIsOpen: pass an updater-function to setState(),
		// instead of reading this.state.menuIsOpen and then calling setState({menuIsOpen:...})
		this.setState((state) => ({
			menuIsOpen: !state.menuIsOpen,
		}));
	};

	isMenuOpen = (state) => {
		this.setState({menuIsOpen: state.isOpen});
	};

	// lifecycle methods
	render() {
		const {children, location, isTabsShow, user} = this.props;
		const {menuIsOpen, render} = this.state;

		const burgerProps = {
			onStateChange: this.isMenuOpen,
			isOpen: menuIsOpen,
			activeSlug: location.pathname,
		};

		const navbarProps = {
			activeSlug: location.pathname,
		};

		// if there is a window object we are out of the build scope and can do null checks
		if (typeof window !== 'undefined') {
			if (render === null) {
				return null;
			}
			if (!user) {
				const findUrl = privateUrl.find((x) => x.path === location.pathname);
				if (findUrl) {
					navigate(findUrl.redirectPath);
				}
			}
		}

		return (
			<div>
				<>
					<GlobalStyle />
					<WindowScrollListener threshold={400} mounted={this.mount}>
						<BurgerMenuWrapper {...burgerProps} />
						<Navbar {...navbarProps} handleBurgerItemClick={this.handleBurgerItemClick} />
						<Banner targetBtnText="OK" />
					</WindowScrollListener>
					<ObserveExternalLinks />
					<DisclaimerExternalLink />
					<GtmEventListener />
					{children}
					<Footer />
					{isTabsShow && <ServiceTabs />}
				</>
			</div>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	location: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
	messages: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	isTabsShow: PropTypes.bool,
	user: PropTypes.oneOfType([PropTypes.object]),
};

Layout.defaultProps = {
	messages: null,
	isTabsShow: true,
	user: null,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(Layout);
