import React from 'react';
import PropTypes from 'prop-types';

import {LightBoxBackdrop, ResetStackContext, LightboxStyled} from './styles';
import {startScrolling, stopScrolling} from '../../../utils/scrollUtils';

class LightBox extends React.PureComponent {
	refOfBackdrop = React.createRef();

	componentDidUpdate(prevProps) {
		const {show: prevShow} = prevProps;
		const {show: thisShow} = this.props;

		if (!prevShow && thisShow) {
			stopScrolling();
		} else if (prevShow && !thisShow) {
			startScrolling();
		}
	}

	handleClickOnBackdrop = ev => {
		const {onRequestToHide} = this.props;
		// only react when the user clicked on the backdrop,
		// not when the event bubbled up from a child element
		if (this.refOfBackdrop.current === ev.target) {
			onRequestToHide();
		}
	};

	render() {
		const {children, show} = this.props;
		return show ? (
			<ResetStackContext>
				<LightBoxBackdrop onClick={this.handleClickOnBackdrop} ref={this.refOfBackdrop}>
					<LightboxStyled>{children}</LightboxStyled>
				</LightBoxBackdrop>
			</ResetStackContext>
		) : null;
	}
}

LightBox.propTypes = {
	children: PropTypes.node,
	onRequestToHide: PropTypes.func.isRequired,
	show: PropTypes.bool
};

LightBox.defaultProps = {
	children: null,
	show: false
};

export default LightBox;
