import React from 'react';
import PropTypes from 'prop-types';
import {HotlineStyled, PhoneNumberStyled, VideoLineStyled} from './styles';
import {Icon} from '../../Elements/Icon/Icon';

const Hotline = (props) => {
	const {className} = props;
	let isIE = false;
	if (typeof window !== 'undefined') {
		isIE = window.navigator.userAgent.indexOf('Trident') !== -1;
	}

	const handlePhoneNumberClick = () => {
		window.dataLayer.push({
			event: 'biib_internalLinkClick',
			eventAsset: 'click on telephone number in the footer',
			eventAssetBis: '0800 030 77 30',
		});
	};

	return (
		<HotlineStyled className={className} isIE={isIE}>
			<strong>Wir sind für dich da: </strong>
			<PhoneNumberStyled href="tel:0800 030 77 30" onClick={handlePhoneNumberClick}>
				<Icon name="phone" /> 0800 030 77 30
			</PhoneNumberStyled>
			<strong> oder </strong>
			<VideoLineStyled className="ap-hotline__video-line" to="/service/service-team/ms-video-line">
				MS Video-Line
			</VideoLineStyled>
		</HotlineStyled>
	);
};

/** @component */
export default Hotline;

Hotline.propTypes = {
	className: PropTypes.string,
};

Hotline.defaultProps = {
	className: 'ap-hotline',
};
