import React from 'react';
import PropTypes from 'prop-types';
import {
	HeadlineStyledH1,
	HeadlineStyledH2,
	HeadlineStyledH3,
	HeadlineStyledH4,
	HeadlineStyledH5,
	HeadlineStyledH6,
} from './styles';

const Headline = (props) => {
	const {center, className, text, as, ...otherProps} = props; // otherProps: like 'id'

	// text might be null - has to be checked!!!
	if (!text) {
		return null;
	}

	const beforeParse = text;

	let afterParse = null;
	let direction = null;

	if (beforeParse.includes('<<')) {
		direction = 'rtl';
	}

	const regex1 = /<</gi;
	const regex2 = />>/gi;

	afterParse = beforeParse.replace(regex1, '<div dir="rtl">');
	afterParse = afterParse.replace(regex2, '</div>');

	const headlineProps = {
		...otherProps,
		center,
		direction,
	};

	switch (as) {
		case 'h1':
			return (
				<HeadlineStyledH1
					itemProp="name"
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		case 'h2':
			return (
				<HeadlineStyledH2
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		case 'h3':
			return (
				<HeadlineStyledH3
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		case 'h4':
			return (
				<HeadlineStyledH4
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		case 'h5':
			return (
				<HeadlineStyledH5
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		case 'h6':
			return (
				<HeadlineStyledH6
					className={className}
					dangerouslySetInnerHTML={{__html: afterParse}}
					{...headlineProps}
				/>
			);
		default:
			return null;
	}
};

Headline.propTypes = {
	/** Needed to overwrite styling via styled components. */
	className: PropTypes.string,
	as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'searchResult']),
	text: PropTypes.string.isRequired,
	center: PropTypes.bool,
};

Headline.defaultProps = {
	as: 'h1',
	className: 'ap-headline',
	center: false,
};

/** @component */
export default Headline;
