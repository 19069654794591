import styled from 'styled-components';
import {zindex, colors, sizes} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';

export const LightBoxBackdrop = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	background-color: ${colors.carrot80};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LightboxStyled = styled.div`
	position: relative;
	box-sizing: border-box;
	background-color: ${colors.white};
	width: calc(100% - ${withUnit(sizes.distance.base)});
	height: auto;
	max-height: calc(100% - ${withUnit(sizes.distance.base)});
	margin: 0;
	padding: ${withUnit(sizes.distance.base)};
	font-size: 0.85em;
	overflow-y: auto;
	overflow-x: hidden;
	max-width: 800px;
	z-index: 1;

	${media.xl`
		padding: ${withUnit(sizes.distance.base * 2)};
	`};
`;

export const ResetStackContext = styled.div`
	position: fixed;
	z-index: ${zindex.lightboxBackdrop};
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
`;
