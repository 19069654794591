import React from 'react';
import PropTypes from 'prop-types';
import {MenuFooterStyled, MenuItemsStyled, MenuItemStyled, MenuItemLinkStyled, MenuItemSpanStyled} from './styles';
import {linkToPrivacyPolicy} from './constants';

const MenuFooter = ({data}) => {
	// remove 'Datenschutzerklärung'-item from items, because we use 'linkToPrivacyPolicy'
	const items = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.filter(
		(item) => item.title !== linkToPrivacyPolicy.title
	);

	return (
		<MenuFooterStyled>
			<MenuItemsStyled>
				<MenuItemStyled data-testid="menuItem" key="copyright">
					<MenuItemSpanStyled to="">©2024</MenuItemSpanStyled>
				</MenuItemStyled>

				<MenuItemStyled data-testid="menuItem">
					<MenuItemLinkStyled to={linkToPrivacyPolicy.href}>{linkToPrivacyPolicy.title}</MenuItemLinkStyled>
				</MenuItemStyled>

				{items.map((edge) => (
					<MenuItemStyled data-testid="menuItem" key={edge.object_slug}>
						<MenuItemLinkStyled to={`/${edge.object_slug}/`}>{edge.title}</MenuItemLinkStyled>
					</MenuItemStyled>
				))}
			</MenuItemsStyled>
		</MenuFooterStyled>
	);
};

export default MenuFooter;

MenuFooter.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
};
