import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';
import {Icon} from '../../Elements/Icon/Icon';

export const SearchBtnBoxStyled = styled.div``;

export const SearchBtnStyled = styled.div`
	display: inline-flex;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	

	color: ${colors.white};
	font: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	z-index: 1001;
	box-sizing: border-box;

	height: 100%;
	align-items: center;
	padding: 0 ${withUnit(sizes.distance.base)};
	border-left: 2px solid ${colors.snow};

	${media.print`
		display: none;
    `};
    
`;

export const SearchBtnIconStyled = styled(Icon)`
	display: flex;
	align-items: center;
	justify-content: center;

	

	z-index: 1;

	&& {
		pointer-events: all;
		cursor: pointer;
		

		svg {
			fill: ${colors.carrot};
			transition: fill 0.3s ease;
		}

		&:hover svg {
			fill: ${colors.candy};
		}
	}
`;
