import _ from 'lodash';
import {hostSiblings} from '../config';
import {HOSTNAME} from './wordpressLinks';

// todo: jsdoc, unit test
export const removeTrailingSlash = str => (str[str.length - 1] === '/' ? str.slice(0, -1) : str);

// todo: jsdoc, unit test
export const extractUrlParts = url => {
	// see https://tools.ietf.org/html/rfc3986#appendix-B
	const regex = /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
	return url.match(regex); // returns an array of url parts
};

// todo: jsdoc, unit test
export const extractPathFromUrl = url => {
	const matchResult = extractUrlParts(url);
	const rawSlug = matchResult[5];
	return removeTrailingSlash(rawSlug);
};

// todo: jsdoc, unit test
export const extractHostFromUrl = url => {
	const matchResult = extractUrlParts(url);
	return matchResult[4];
};

/*
 * memoize (= cache) the results of isInternalDestination() and isSiblingDestination()
 * for each destination (otherwise they would be called on each render)
 *
 */

export const isInternalDestination = _.memoize(to => {
	// Remove url from link
	if (!to) {
		return to; // to is null, undefined or an empty string
	}
	const destination = to.replace(HOSTNAME, '');
	return /^\/(?!\/)/.test(destination);
});

export const isSiblingDestination = _.memoize(to => {
	// returns true, if hostName (part of 'to') matches any item in hostSiblings
	return _.some(hostSiblings, regexStr => {
		// regexStr we allow asterisks in hostSiblings/regexStr as wildcards, but must replace them here
		const hostName = extractHostFromUrl(to);
		return hostName && hostName.match(new RegExp(regexStr, 'i'));
	});
});

export const isDownload = _.memoize(to => {
	return /(\.[a-zA-Z]{3,4}\b)/.test(to);
});
