import React from 'react';
import {PhoneNumberStyled} from './styles';
import {Icon} from '../../Elements/Icon/Icon';

const PhoneNumber = () => {
	return (
		<PhoneNumberStyled className="ap-phonenumber" href="tel:0800 030 77 30">
			<Icon name="phone" /> 0800 030 77 30
		</PhoneNumberStyled>
	);
};

/** @component */
export default PhoneNumber;
