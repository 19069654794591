import styled from 'styled-components';
import {colors, sizes} from '../../../../styles/variables';
import {Icon} from '../../../Elements/Icon/Icon';

const searchBarHeight = '3.2em';

export const SearchFormStyled = styled.div`
	display: flex;
	position: relative;
	border-radius: ${sizes.borderRadius};
	width: ${props => (props.isOpen ? `${props.fullWidth ? `100%` : `250px`}` : searchBarHeight)};
	height: ${searchBarHeight};
	min-height: ${searchBarHeight};
	transition: width 0.3s ease;
	font-size: 0.8em;

	::-ms-clear {
		display: none;
	}
`;

export const SearchInputStyled = styled.input`
	box-sizing: border-box;
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;
	background-color: ${colors.snow};
	border-radius: ${sizes.borderRadius};
	z-index: 0;
	padding: ${props => (props.isOpen ? `0 1.2em` : `0`)};
	opacity: ${props => (props.isOpen ? '1' : '0')};
	border: 0;
	transition: ${props => (props.isOpen ? `all 0.3s ease` : `all 0.3s ease`)};

	&::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	&:focus {
		outline: 0;
		border: 1px solid ${colors.carrot};
	}

	::-webkit-input-placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}
	:-moz-placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}
	::-moz-placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}
	:-ms-input-placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}
	::-ms-input-placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}

	::placeholder {
		color: ${colors.black};
		font-size: 0.8em;
	}
`;

export const SearchIconStyled = styled(Icon).attrs(props => ({
	height: props.height || '1.5em',
	width: props.width || '1.5em'
}))`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: ${props => (props.isOpen ? '0.5em' : '0')};
	transition: right 0.3s ease;

	z-index: 1;

	&& {
		pointer-events: all;
		cursor: pointer;
		width: ${searchBarHeight};
		height: ${searchBarHeight};
		${props => (props.isOpen ? `width: ${searchBarHeight};` : '')};
		${props => (props.isOpen ? `height: ${searchBarHeight};` : '')};

		svg {
			fill: ${colors.carrot};
			transition: fill 0.3s ease;
		}

		&:hover svg {
			fill: ${colors.candy};
		}
	}
`;
