import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {scaleDown as Menu} from 'react-burger-menu';
import _ from 'lodash';
import {
	StyledBurgerMenu,
	LayerOne,
	LayerTwo,
	LayerThree,
	MenuItemsContainer,
	MenuItemContainer,
	SubMenuItemContainer,
	BackLink,
	IconStyled,
	StyledLink,
	HeadlineStyled
} from './styles';
import SearchForm from './components/SearchFormContainerForBurgerMenu';
import CurrentUser from '../../Container/CurrentUser/CurrentUser';
import Hotline from '../../Content/Hotline/Hotline';
import config from '../../../config';

class BurgerMenu extends React.Component {
	constructor(props) {
		super(props);

		const {
			data: {allWordpressWpApiMenusMenusItems}
		} = this.props;

		const rootMenuItems = allWordpressWpApiMenusMenusItems.edges[1].node.items;

		this.state = {
			rootMenuItems,
			level2Items: null,
			level3Items: null,
			activeLevel2: false,
			activeLevel3: false,
			activeLevel2Title: '',
			activeLevel3Title: ''
		};

		// bindings
		this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
		this.handleMenuItemClickLevel2 = this.handleMenuItemClickLevel2.bind(this);
		this.handleBackLevel1 = this.handleBackLevel1.bind(this);
		this.handleBackLevel2 = this.handleBackLevel2.bind(this);
	}

	componentDidMount() {
		const {activeSlug} = this.props;
		this.initState(activeSlug);
	}

	shouldComponentUpdate = nextProps => {
		const {currentPosition} = this.props;
		// avoid rerendering when parent component is listening for scrolls
		const scrollPositionChanged = currentPosition !== nextProps.currentPosition;
		if (scrollPositionChanged) {
			return false;
		}
		return true;
	};

	closeMenu = () => {
		// called when search form handles search
		const {onStateChange} = this.props;
		onStateChange({isOpen: false});
	};

	initState(activeSlug) {
		const {rootMenuItems} = this.state;

		let slug = activeSlug.split('/');
		slug = slug.filter(el => {
			// KK: Exception for blog page -> do not show second level menu on article pages (!)
			return el !== null && el !== '' && el !== 'leben-mit-ms' && el !== 'spezial' && el !== '404';
		});

		const trunk = activeSlug.split('/')[1];

		if (slug.length >= 1 && (trunk.includes('multiple-sklerose')|| trunk.includes('service'))) {
			let level2Items = [];
			let activeLevel2Title = '';

			const filteredSubMenu = rootMenuItems.filter(item => {
				
				return item.object_slug === slug[0];
			});

			

			if (filteredSubMenu.length > 0 && filteredSubMenu[0].wordpress_children) {
				level2Items = filteredSubMenu[0].wordpress_children;
				activeLevel2Title = filteredSubMenu[0].title;
			}

			

			if (slug.length >= 2) {
				let level3Items = null;
				let activeLevel3Title = '';
			
					const filteredSubMenuLevel2 = level2Items.filter(item => {
						return item.object_slug === slug[1];
					});
				
				

				if (filteredSubMenuLevel2.length > 0 && filteredSubMenuLevel2[0].wordpress_children) {
					level3Items = filteredSubMenuLevel2[0].wordpress_children;
					activeLevel3Title = filteredSubMenuLevel2[0].title;
				}

				this.setState(prevState => ({
					activeLevel2: !prevState.activeLevel2,
					level2Items,
					activeLevel2Title,
					activeLevel3: !prevState.activeLevel3,
					level3Items,
					activeLevel3Title
				}));
			} else {
				setTimeout(() => {
					this.setState(prevState => ({
						activeLevel2: !prevState.activeLevel2,
						level2Items,
						activeLevel2Title
					}));
				}, 0);
			}
		}
	}

	activateLevel1(slug) {
		const {rootMenuItems} = this.state;

		let level2Items = null;
		let activeLevel2Title = '';

		const filteredSubMenu = rootMenuItems.filter(item => {
			return item.object_slug === slug;
		});

		if (filteredSubMenu.length > 0 && filteredSubMenu[0].wordpress_children) {
			level2Items = filteredSubMenu[0].wordpress_children;
			activeLevel2Title = filteredSubMenu[0].title;
		}

		setTimeout(() => {
			this.setState(prevState => ({
				activeLevel2: !prevState.activeLevel2,
				level2Items,
				activeLevel2Title
			}));
		}, 0);
	}

	activateLevel2(slug) {
		const {level2Items} = this.state;

		let level3Items = null;
		let activeLevel3Title = '';

		const filteredSubMenu = level2Items.filter(item => {
			return item.object_slug === slug;
		});

		if (filteredSubMenu.length > 0 && filteredSubMenu[0].wordpress_children) {
			level3Items = filteredSubMenu[0].wordpress_children;
			activeLevel3Title = filteredSubMenu[0].title;
		}

		this.setState(prevState => ({activeLevel3: !prevState.activeLevel3, level3Items, activeLevel3Title}));
	}

	handleMenuItemClick(e) {
		const activeMenu = e.target.dataset.slug;
		this.activateLevel1(activeMenu);
	}

	handleMenuItemClickLevel2(e) {
		const activeMenu = e.target.dataset.slug;
		this.activateLevel2(activeMenu);
	}

	handleBackLevel1() {
		this.setState(prevState => ({activeLevel2: !prevState.activeLevel2}));
	}

	handleBackLevel2() {
		this.setState(prevState => ({activeLevel3: !prevState.activeLevel3}));
	}

	/**
	 * RENDER FUNCTIONS
	 */

	renderRootMenuItems() {
		const {rootMenuItems} = this.state;
		const {activeSlug} = this.props;

		const topHalf = rootMenuItems.slice(0,2)
		const bottomHalf = rootMenuItems.slice(2)

		const menuItems = topHalf.map(item => {
			let menuNav = null;
			if (item.wordpress_children) {
				menuNav = (
					<IconStyled
						name="chevron_right"
						data-testid={item.object_slug}
						key={item.object_slug}
						data-slug={item.object_slug}
						onClick={this.handleMenuItemClick}
					/>
				);
			}

			return (
				<MenuItemContainer key={item.object_slug}>
					<StyledLink
						to={item.type === 'custom' ? `${item.url}` : `/${item.object_slug}`}
						active={activeSlug.includes(`/${item.path}`) ? 1 : 0}>
						{item.title}
					</StyledLink>
					{menuNav}
				</MenuItemContainer>
			);
		});

		const menuItems2 = bottomHalf.map(item => {
			let menuNav = null;
			if (item.wordpress_children) {
				menuNav = (
					<IconStyled
						name="chevron_right"
						data-testid={item.object_slug}
						key={item.object_slug}
						data-slug={item.object_slug}
						onClick={this.handleMenuItemClick}
					/>
				);
			}

			return (
				<MenuItemContainer key={item.object_slug}>
					<StyledLink
						to={item.type === 'custom' ? `${item.url}` : `/${item.object_slug}`}
						active={activeSlug.includes(`/${item.path}`) && !activeSlug.includes(`/service/ms-behandlung`) ? 1 : 0}>
						{item.title}
					</StyledLink>
					{menuNav}
				</MenuItemContainer>
			);
		});
		return (
			<MenuItemsContainer>
				{menuItems}
				<MenuItemContainer>
					<StyledLink
						to={`/service/ms-behandlung`}
						active={activeSlug.includes(`/service/ms-behandlung`) ? 1 : 0}>
						Behandlung
					</StyledLink>
					
				</MenuItemContainer>
				{menuItems2}
				<CurrentUser />
			</MenuItemsContainer>
		);
	}

	renderLayerTwo() {
		const {level2Items, activeLevel2Title} = this.state;
		const {activeSlug} = this.props;
		let subMenuItems = null;
		if (level2Items) {
			subMenuItems = level2Items.map(item => {
				let menuNav = null;
				if (item.wordpress_children) {
					menuNav = (
						<IconStyled
							name="chevron_right"
							key={item.object_slug}
							data-testid={`layer2-${item.object_slug}`}
							data-slug={item.object_slug}
							onClick={this.handleMenuItemClickLevel2}
						/>
					);
				}
				return (
					<SubMenuItemContainer data-testid={`layerTwoContainer-${item.object_slug}`} key={item.object_slug}>
						<StyledLink
							to={item.type === 'custom' ? `${item.url}` : `/${item.path}`}
							active={activeSlug.includes(`/${item.path}`) ? 1 : 0}>
							{item.title}
						</StyledLink>
						{menuNav}
					</SubMenuItemContainer>
				);
			});
		}

		return (
			!!subMenuItems && (
				<>
					<HeadlineStyled>{activeLevel2Title}</HeadlineStyled>
					{subMenuItems}
				</>
			)
		);
	}

	renderLayerThree() {
		const {activeSlug, pzn} = this.props;
		const {level3Items, activeLevel3Title} = this.state;
		const BlackList = config.login.special.split(',');
		let FilterList = null;
		if (pzn) {
			FilterList = BlackList.filter(item => item !== pzn.slug);
		} else {
			FilterList = BlackList;
		}
		let subMenuItems = null;
		if (level3Items) {
			subMenuItems = level3Items.map(item => {
				return (
					_.indexOf(FilterList, item.object_slug) < 0 && (
						<SubMenuItemContainer data-testid="layerThreeContainer" key={item.object_slug}>
							<StyledLink
								to={`/${item.path}`}
								key={item.object_slug}
								data-slug={item.object_slug}
								active={activeSlug.includes(`/${item.path}`) ? 1 : 0}>
								{item.title}
							</StyledLink>
						</SubMenuItemContainer>
					)
				);
			});
		}

		return (
			!!subMenuItems && (
				<>
					<HeadlineStyled>{activeLevel3Title}</HeadlineStyled>
					{subMenuItems}
				</>
			)
		);
	}

	render() {
		const {onStateChange, isOpen} = this.props;
		const {activeLevel2, activeLevel3} = this.state;
		const rootItems = this.renderRootMenuItems();
		const layerTwoItems = this.renderLayerTwo();
		const layerThreeItems = this.renderLayerThree();

		return (
			<StyledBurgerMenu className="ap-burger-menu">
				<Menu onStateChange={onStateChange} isOpen={isOpen} customBurgerIcon={false} width="40%" right>
					<LayerOne>
						<SearchForm autoSubmit={false} fullWidth onSearchRequest={this.closeMenu} />
						{rootItems}

						<Hotline place="burger" />
					</LayerOne>
					<LayerTwo data-testid="layer2" active={!!activeLevel2 && !!layerTwoItems}>
						<BackLink onClick={this.handleBackLevel1}>
							<IconStyled name="arrow_left" /> zurück
						</BackLink>
						{layerTwoItems}
					</LayerTwo>
					<LayerThree active={!!activeLevel3 && !!layerThreeItems}>
						<BackLink onClick={this.handleBackLevel2}>
							<IconStyled name="arrow_left" /> zurück
						</BackLink>
						{layerThreeItems}
					</LayerThree>
				</Menu>
			</StyledBurgerMenu>
		);
	}
}

const mapStateToProps = state => ({
	pzn: state.userManagement.pzn
});

export default connect(mapStateToProps)(BurgerMenu);

BurgerMenu.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	onStateChange: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	activeSlug: PropTypes.string.isRequired,
	currentPosition: PropTypes.number.isRequired
};
