import React from 'react';
import PropTypes from 'prop-types';

export default class WindowScrollListener extends React.PureComponent {
	// props
	static propTypes = {
		children: PropTypes.node,
		threshold: PropTypes.number,
		mounted: PropTypes.bool
	};

	static defaultProps = {
		children: null,
		threshold: 400,
		mounted: false
	};

	// instance variables

	state = {
		currentPosition: 0,
		upstairs: true
	};

	ticking = false;
	// Considering the fact that we can have multiple instances of WindowScrollListener,
	// 'ticking' is an instance variable (unlike in the previous version in Layout.js,
	//  where it was a global value)

	// lifecycle methods

	componentDidMount() {
		window.addEventListener('scroll', this.requestScrollUpdate, true);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.requestScrollUpdate, true);
	}

	// methods bound to 'this'

	requestScrollUpdate = () => {
		if (!this.ticking) {
			window.requestAnimationFrame(this.handleScrollUpdate);
		}
		this.ticking = true;
	};

	handleScrollUpdate = () => {
		const {threshold} = this.props;
		const {pageYOffset} = window;
		this.setState({
			currentPosition: pageYOffset,
			upstairs: pageYOffset < threshold
		});
		this.ticking = false;
	};

	render() {
		const {children, mounted} = this.props; // children can be zero, one or more elements
		const {currentPosition, upstairs} = this.state;
		const propsForChildren = {currentPosition, upstairs, isUpstairs: upstairs};
		return mounted ? React.Children.map(children, child => React.cloneElement(child, propsForChildren)) : null;
	}
}
