import React from 'react';
import PropTypes from 'prop-types';
import LightBox from '../LightBox/LightBox';
import {HeadlineStyled, ButtonContainerStyled, TextStyled, IconStyled, IconContainerStyled} from './styles';
import Button from '../../Elements/Button/Button';
import Headline from '../../Elements/Headline/Headline';

export const CLASSNAME_AP_DISCLAIMER_CONTINUE = 'ap-disclaimer__continue'; // is also used in another module

const DisclaimerExternalLink = ({to, onRequestToHide, ...otherProps}) => {
	const show = !!to; // when "to"-target is not null/undefined, show disclaimer, otherwise hide it
	return (
		<LightBox onRequestToHide={onRequestToHide} show={show}>
			<HeadlineStyled>Wichtiger Hinweis</HeadlineStyled>
			<TextStyled>
				<p>
					Sie verlassen jetzt die Webseite der Biogen GmbH.
					<br />
					Wir empfehlen Ihnen, die Nutzungsbedingungen, Datenschutz-Richtlinien oder andere Rechtliche
					Hinweise der aufgerufenen Webseite zu lesen und zu prüfen, da sie von den Vorgaben der Biogen GmbH,
					Deutschland, abweichen können.
				</p>

				<Headline text="Websites von Dritten" as="h3" />

				<p>
					Biogen übernimmt keinerlei Haftung oder Kontrolle, Verantwortung oder Garantie für Ihre Nutzung der
					Webseiten. Die Tatsache, dass ein Link zur Webseite eines Dritten besteht impliziert keinerlei
					Billigung oder Empfehlung dieser Webseite durch die Biogen GmbH, Deutschland.
				</p>

				<p>Danke, dass Sie unsere Website besucht haben.</p>
				<ButtonContainerStyled>
					<Button className="ap-disclaimer__cancel" onClick={onRequestToHide} theme="snow" text="Abbrechen" />{' '}
					<Button
						type="link"
						className={CLASSNAME_AP_DISCLAIMER_CONTINUE}
						to={to}
						onClick={onRequestToHide}
						{...otherProps}
						text="Weiter"
					/>
				</ButtonContainerStyled>
			</TextStyled>
			<IconContainerStyled>
				<IconStyled name="external" type="teaser" />
			</IconContainerStyled>
		</LightBox>
	);
};

DisclaimerExternalLink.propTypes = {
	to: PropTypes.string,
	onRequestToHide: PropTypes.func.isRequired
};

DisclaimerExternalLink.defaultProps = {
	to: null
};

export default DisclaimerExternalLink;
