import styled from 'styled-components';
import {Link} from 'gatsby';
import {colors, fonts, sizes} from '../../../styles/variables';
import {withUnit} from '../../../styles/utils';
import IconButton from '../../Elements/IconButton/IconButton';

export const UserBlob = styled.div`
	margin-top: ${withUnit(sizes.distance.base / 2)};
`;

export const UserName = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
`;

export const UserAction = styled.div`
	.user__logout {
		box-shadow: none;
		font-size: 1.1em;
		margin: 0;
		padding: 0;
		border-radius: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: ${fonts.sans_serif};

		&:hover {
			color: ${colors.carrot};
		}
	}
`;

export const LoginButtonStyled = styled(IconButton)`
	display: flex;
	align-items: center;

	p {
		font-size: ${withUnit(sizes.fonts.base)};
	}
`;

export const IconStyled = styled(IconButton)`
	width: 3em;
	height: 3em;

	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8em;

	&:hover {
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
		transform: translateY(-2px);
	}

	.ap-icon {
		font-size: 1em;
	}
`;

export const TextStyled = styled.div`
	display: block;
	margin-left: 0.5em;
	line-height: 1.1;

	span {
		display: block;
		font-size: 0.7em;
		font-style: italic;
		margin: 0;
		padding: 0;
	}
`;

export const LinkStyled = styled(Link)`
	display: block;
	margin-bottom: ${withUnit(sizes.distance.base)};
	// font-weight: 600;
	// text-decoration: none;
	// position: relative;
	// color: ${colors.black};
	// z-index: 10;
	// display: flex;
	// align-items: center;
	// justify-content: center;
`;
