import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	MenuItemsStyled,
	MenuItemStyled,
	MenuItemLinkStyled,
	MenuItemExternalLinkStyled,
	MenuMainStyled,
} from './styles';
// import {Icon} from '../../Elements/Icon/Icon';

class MenuMainContent extends React.Component {
	render() {
		const {upstairs, activeSlug, data, user} = this.props;
		const firstHalf = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.slice(0, 2);
		const secondHalf = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.slice(2);

		return (
			<>
				<MenuMainStyled>
					<MenuItemsStyled>
						{firstHalf.map((edge) => (
							<MenuItemStyled key={edge.object_slug}>
								{edge.type !== 'custom' ? (
									<MenuItemLinkStyled
										className="navbar-item"
										upstairs={upstairs ? 1 : 0}
										to={`/${edge.object_slug}`}
										active={activeSlug.includes(`/${edge.object_slug}`) ? 1 : 0}>
										{edge.title}
									</MenuItemLinkStyled>
								) : (
									<MenuItemExternalLinkStyled
										className="navbar-item"
										upstairs={upstairs ? 1 : 0}
										href={`${edge.url}`}
										active={activeSlug.includes(`/${edge.object_slug}`) ? 1 : 0}>
										{edge.title}
									</MenuItemExternalLinkStyled>
								)}
							</MenuItemStyled>
						))}
						<MenuItemStyled>
							<MenuItemLinkStyled
								className="navbar-item"
								upstairs={upstairs ? 1 : 0}
								to="/service/ms-behandlung"
								active={activeSlug.includes(`/ms-behandlung`) ? 1 : 0}>
								Behandlung
							</MenuItemLinkStyled>
						</MenuItemStyled>
						{secondHalf.map((edge) => (
							<MenuItemStyled key={edge.object_slug}>
								{edge.type !== 'custom' ? (
									<MenuItemLinkStyled
										className="navbar-item"
										upstairs={upstairs ? 1 : 0}
										to={`/${edge.object_slug}`}
										active={
											activeSlug.includes(`/${edge.object_slug}`) &&
											!activeSlug.includes(`/ms-behandlung`)
												? 1
												: 0
										}>
										{edge.title}
									</MenuItemLinkStyled>
								) : (
									<MenuItemExternalLinkStyled
										className="navbar-item"
										upstairs={upstairs ? 1 : 0}
										href={`${edge.url}`}
										active={activeSlug.includes(`/${edge.object_slug}`) ? 1 : 0}>
										{edge.title}
									</MenuItemExternalLinkStyled>
								)}
							</MenuItemStyled>
						))}
					</MenuItemsStyled>
				</MenuMainStyled>
				{/* <MenuUserStyled>
					<MenuItemUserStyled key="dashboard">
						<DashboardLinkStyled
							className="navbar-item"
							upstairs={upstairs ? 1 : 0}
							to={user ? '/dashboard' : '/login'}>
							<IconStyled className="ap-icon-button">
								<Icon name={user ? 'user' : 'login'} />
							</IconStyled>
							{!user && <TextStyled>Anmelden</TextStyled>}
							{user && (
								<TextStyled>
									<span>Hallo,</span>
									{user.FirstName}
								</TextStyled>
							)}
						</DashboardLinkStyled>
					</MenuItemUserStyled>
				</MenuUserStyled> */}
			</>
		);
	}
}

MenuMainContent.propTypes = {
	upstairs: PropTypes.bool.isRequired,
	activeSlug: PropTypes.string.isRequired,
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	user: PropTypes.shape({FirstName: PropTypes.string}),
};

MenuMainContent.defaultProps = {
	user: null,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(MenuMainContent);
