import styled from 'styled-components';
import {gradientCandyCarrot, gradientCandyCarrotRevert, media, withUnit} from '../../../styles/utils';
import {colors, sizes} from '../../../styles/variables';
import {motion} from 'framer-motion';

export const ServiceTabsStyled = styled(motion.div)`
	position: fixed;
	bottom: 22px;
	right: 8px;
	width: auto;
	z-index: 999999;
	visibility: visible;

	&:before {
		opacity: 1;
	}
`;

export const TabsStyled = styled(motion.ul)`
	display: flex;
	z-index: 1;
`;

export const BtnUpStyled = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	padding: 0;
	border-radius: 30px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: 2px 2px 4px black;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin-top: 0 !important;
	}

	
`;

export const TabStyled = styled(motion.li)`
	width: 25%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-direction: column;
	box-sizing: border-box;
	width: 60px;
	height: 60px;

	padding: ${withUnit(sizes.distance.base / 4)};

	img {
		border-radius: 50%;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
		

		&:hover {
			box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.4);
			
		}

		&:focus, &:active {
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
			
		}
	}

	.ap-icon {
		display: block;
		font-size: 1.5rem;

		svg {
			fill: ${colors.white};
		}
	}

	&.service {
		position: relative;
	}

	&.close {
		position: absolute;
		right: 0;
		bottom: 0px;
	}

	&.close-2 {
		position: absolute;
		right: 0;
		bottom: 50px;
	}

	&.btn-up {
		position: absolute;
		right: 0;
		padding: 5px;
		bottom: -15px;
	}

	span {
		display: none;
		color: ${colors.white};
		font-size: 1em;
		line-height: 1;
		text-align: center;
		font-weight: 600;
		margin-top: 5px;

		display: block;
		width: 100%;
	}

	&:last-child {
		.ap-icon {
			font-size: 1.1rem;
		}
	}
`;

export const CloseStyled = styled.div`
	position: absolute;
	display: block;
	bottom: ${withUnit(sizes.distance.base / 2)};
	right: ${withUnit(sizes.distance.base / 4)};
	width: 1rem;
	height: 1rem;
	cursor: pointer;

	.ap-icon {
		display: block;
		font-size: 0.8rem;
		svg {
			fill: ${colors.white};
		}
	}
`;

export const PanelStyled = styled.div`
	${gradientCandyCarrotRevert()};
	${(props) => (props.active ? `display: block;` : `display: none;`)};
	box-sizing: border-box;
	position: absolute;

	bottom: 32px;
	right: 19px;

	left: auto;
	height: auto;

	span {
		color: black;
	}

	width: auto;

	padding: ${withUnit(sizes.distance.base / 2)};
	color: ${colors.white};
	font-size: 0.8rem;
	text-align: center;

	&.tiny {
		background: transparent;
		height: 100px;
		border-radius: 50%;
		bottom: 0px;
		right: 0px;
	}

	height: auto;
	border-radius: 12px;

	.ap-phonenumber {
		font-size: 1.5rem;
		display: block;
	}

	a:not(.ap-button) {
		&,
		&:visited,
		&:hover,
		&:active,
		&:focus {
			color: ${colors.white};
		}
	}
`;

export const ImageStyled = styled.img`
	width: 55px;
	height: 55px;
`;
